import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '../../../core/services/APIService/auth.service';
import { Router } from '@angular/router';
import { environment } from '@studentapp_env/environment';

@Component({
  selector: 'app-student-selection-dialog',
  templateUrl: './student-selection-dialog.component.html',
  styleUrls: ['./student-selection-dialog.component.scss']
})
export class StudentSelectionDialogComponent implements OnInit {
  env = environment;
  imagePath = this.env.cdn_urls.image;

  students_data: any[] = [];
  isDisabled: Boolean = false;

  currentUserData: any = null;
  currentStudent: any = null;

  constructor(
    public authService: AuthService,
    public dialogRef: MatDialogRef<StudentSelectionDialogComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    this.students_data = data.students;
    this.currentUserData = data.user;
  }

  addNewStudent() {
    this.dialogRef.close();
    this.router.navigate(['', { outlets: { modal: 'student/addStudent' }}]);
  }

  /* get seletec student's data and pass it no another compoent */
  getSeletedStudent(index) {
    this.isDisabled = true;
    setTimeout(() => {
      this.dialogRef.close(this.students_data[index]);
      this.isDisabled = false;
    }, 1000);
  }

  ngOnInit() {
    this.currentStudent = this.authService.getCurrentStudentData();
  }

}
