import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BottomNavbarDefaultComponent } from './bottom-navbar-default.component';
import { RouterModule } from '@angular/router';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { ProductSwitcherBottomSheetModule } from '../product-switcher-bottom-sheet/product-switcher-bottom-sheet.module';

@NgModule({
  declarations: [
    BottomNavbarDefaultComponent
  ],
  imports: [
    CommonModule,
    MatBottomSheetModule,
    RouterModule,
    ProductSwitcherBottomSheetModule,
  ],
  exports: [
    BottomNavbarDefaultComponent
  ]
})
export class BottomNavbarDefaultModule { }
