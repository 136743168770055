import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class InformationService {

  constructor(
    private deviceService: DeviceDetectorService,
    public platform: Platform) {
    console.log('InformationService');
    console.log(this.deviceService.getDeviceInfo());
  }


  getOSDetails() {
    let OBJ = {
      type: this.deviceService.getDeviceInfo().os,
      version: this.deviceService.getDeviceInfo().os_version
    }
    return OBJ;
  }

  getBrowser() {
    return this.deviceService.getDeviceInfo().browser;
  }

  getDevice() {
    if (this.deviceService.isMobile()) return 'Mobile';
    else if (this.deviceService.isDesktop()) return 'Desktop';
    else if (this.deviceService.isTablet()) return 'Tablet';
    else return 'Unknown';
  }

  getResolution() {
    let OBJ = {
      height: (<any>window).innerHeight,
      width: (<any>window).innerWidth
    }
    return OBJ;
  }

}
