import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SKIP_ALL_KIND_RESOLVER_API_CALL } from '@studentapp_core/common/constant';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-top-navbar-default',
  templateUrl: './top-navbar-default.component.html',
  styleUrls: ['./top-navbar-default.component.scss']
})
export class TopNavbarDefaultComponent implements OnInit, OnDestroy {

  @Input() loginRouteQueryParamsHandling = 'preserve';
  @Input() loginRouteQueryParams = null;
  @Input() showMainNavFeature = true;
  @Input() showBackPreviousPageFeature = false;
  @Input() productLogoPath = '';

  @Output() backToPreviousPageEvent = new EventEmitter<any>();

  resolverSkipObjectData = SKIP_ALL_KIND_RESOLVER_API_CALL;
  subscriptions: Subscription[] = [];

  constructor() {
  }

  show_main_nav() {
    document.getElementById('overlay-superbook').classList.toggle('open');
    document.body.classList.toggle('hide-main-nav');
    document.body.classList.remove('show-rightPanel');
    document.body.classList.remove('show-doubtsPanel');
  }

  backPreviousPage() {
    this.backToPreviousPageEvent.emit();
  }

  ngOnInit(): void {
    if (!this.showMainNavFeature) {
      document.body.classList.add('hide-main-nav');
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

}
