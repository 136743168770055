import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductSwitcherBottomSheetComponent } from './product-switcher-bottom-sheet.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    ProductSwitcherBottomSheetComponent
  ],
  imports: [
    CommonModule,
    MatBottomSheetModule,
    RouterModule,
  ],
  exports: [
    ProductSwitcherBottomSheetComponent
  ]
})
export class ProductSwitcherBottomSheetModule { }
