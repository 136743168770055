import { Component, OnDestroy, OnInit, Compiler, AfterViewInit, HostListener } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './core/services/APIService/auth.service';
import { DataService } from './core/services/DataService/data.service';
import { ReferalSourceDetectService } from './core/services/referal-source-detect.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostListener('window:resize', ['$event']) resizeBy() {
    this.vhHeight();
  }
  env = environment;
  studentData = null;
  productInfo = null;
  subscriptions: Subscription[] = [];

  liveClasscomponent: any = null;
  liveClassModule: any = null;

  dynamicPageToDisplay = '';
  callFloating = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    public dataService: DataService,
    private compiler: Compiler,
    private referalSourceDetectService: ReferalSourceDetectService,
  ) {
    console.log('Current App Mode: ', Capacitor.getPlatform());
    this.listenForRouterChangeEvent();
    this.getQueryParamsValue();
    this.detectSourceQueryParamChanges();
  }

  vhHeight(){    
    var windowInnerHeight = window.innerHeight;    
    if(document.getElementsByClassName('show-bottom-menu').length){
      windowInnerHeight = window.innerHeight - 48;
      console.log('Mobile nav on');
    }
    else {
      console.log('Mobile nav off');
    }
    // document.querySelector(':root')[0].style.setProperty('--screen-vh', windowInnerHeight);
    const css = '@media screen and (max-width: 1023px){:root {--screen-vh: '+ windowInnerHeight +'px;}}';
    const head = document.getElementsByTagName('head')[0];
    const style = document.createElement('style');
    style.type = 'text/css';
    style.setAttribute("id", "vhStyle");
    style.appendChild(document.createTextNode(css));

    const myEle = document.getElementById("vhStyle");
    if(myEle){
      myEle.remove();
      head.appendChild(style);    
    }else{
      head.appendChild(style);
    }
    // alert('VH DONE');
  }

  listenForRouterChangeEvent() {
    this.subscriptions.push(this.router.events.subscribe(
      (event: RouterEvent) => {
        if (event instanceof NavigationEnd) {
          if(this.activatedRoute.snapshot.children[0].data && this.activatedRoute.snapshot.children[0].data.branchLevel) {
            this.productInfo = this.activatedRoute.snapshot.children[0].data.branchLevel;
          }
          else {
            this.productInfo = 'doerDo';
          }
          setTimeout(() => {
            this.vhHeight();
          }, 3000);
        }
      }
    ));
  }

  getQueryParamsValue() {
    this.subscriptions.push(this.activatedRoute.queryParams.subscribe(
      (response: any) => {
        this.dynamicPageToDisplay = response.show_page ? response.show_page : '';
        console.log(this.dynamicPageToDisplay);
        if(this.dynamicPageToDisplay === 'LIVE_CLASS') {
          this.callFloating = response.float_window ? response.float_window : '';
          console.log(this.callFloating);
          this.loadComponent();
        }
        this.dataService.updateDynamicPageConfig({ pageName: this.dynamicPageToDisplay, floating: this.callFloating });
      })
    );
  }

  detectSourceQueryParamChanges() {
    this.subscriptions.push(
      this.activatedRoute.queryParams.pipe(
        distinctUntilChanged((prev, curr) => prev.refer_source === curr.refer_source),
      ).subscribe(
        (response: any) => {
          if (response.refer_source) {
            this.referalSourceDetectService.UpdateReferalSourceValueInSessionStorage({ sourceKey: response.refer_source });
            this.referalSourceDetectService.executeAllUnProcessedReferalSources();
          }
        }
      )
    );
  }

  async loadComponent() {
    if(!this.liveClasscomponent || !this.liveClassModule) {
      const { LiveClassViewComponent } = await import('./content/live-class-view/live-class-view.component');
      const { LiveClassViewModule } = await import('./content/live-class-view/live-class-view.module');
      this.liveClasscomponent = LiveClassViewComponent;
      this.liveClassModule = await this.compiler.compileModuleAsync(LiveClassViewModule);
    }
  }

  getCurrentStudentData() {
    this.studentData = this.authService.getCurrentStudentData();
    if(this.studentData) {
      document.body.classList.remove('guest-mode');
    }
    else {
      document.body.classList.add('guest-mode');
    }
  }

  remove_nav(){
    document.body.classList.add("hide-main-nav");
    document.getElementById('overlay-superbook').classList.remove('open');
  }

  showAppVersion() {
    const basicCss = 'padding: 8px; font-size: 15px; font-weight: 700';
    const headingColor = 'background: #511CD9;' + basicCss;
    const versionColor = 'background: #138bee;' + basicCss;
    console.log('%cApp Version' + `%cv-${this.env.version}`, headingColor, versionColor);
  }

  showAppConfigMode() {
    const basicCss = 'padding: 8px; font-size: 15px; font-weight: 700';
    const headingColor = 'background: #ff587e;' + basicCss;
    const versionColor = 'background: #1b71f5;' + basicCss;
    console.log('%cConfig Mode' + `%c${this.env.configMode}`, headingColor, versionColor);
  }

  ngOnInit(): void {
    this.showAppVersion();
    this.showAppConfigMode();
    this.getCurrentStudentData();
    this.subscriptions.push(this.dataService.StorageUpdate$.subscribe(
      (storageUpdate: Boolean) => {
        if (storageUpdate) {
          this.getCurrentStudentData();
          this.dataService.update_storage(false);
        }
      }
    ));    
    this.vhHeight();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.loadComponent();
    }, 10000);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());    
  }
}
