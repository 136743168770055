import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../../../core/services/APIService/auth.service';
import { environment } from '@studentapp_env/environment';

@Component({
  selector: 'app-ask-doubt-dialog',
  templateUrl: './ask-doubt-dialog.component.html',
  styleUrls: ['./ask-doubt-dialog.component.scss'] 
})
export class AskDoubtDialogComponent implements OnInit {

  Form: FormGroup;
  availableCredit: number = 0;
  uploadStatus: any[] = ['success', 'in-progress', 'failed'];

  env = environment;
  imagePath = this.env.cdn_urls.image;

  constructor(
    public dialogRef: MatDialogRef<AskDoubtDialogComponent>,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.dialogRef.disableClose = true;
    this.availableCredit = data.available_credit;
    this.Form = new FormGroup({
      CATEGORY: new FormControl(null, Validators.required),
      QUESTION: new FormControl('', [Validators.required, Validators.maxLength(500)]),
      IMAGE: new FormGroup({
        KEY: new FormControl(null),
        NAME: new FormControl(null),
        URL: new FormControl(null),
        UPLOAD_STATUS: new FormControl(null)
      }),
    });
  }


  get category() {
    return this.Form.get('CATEGORY');
  }

  get question() {
    return this.Form.get('QUESTION');
  }

  get key() {
    return this.Form.get('IMAGE').get('KEY');
  }

  get name() {
    return this.Form.get('IMAGE').get('NAME');
  }

  get url() {
    return this.Form.get('IMAGE').get('URL');
  }

  get upload_status() {
    return this.Form.get('IMAGE').get('UPLOAD_STATUS');
  }

  newImageAdded(event: any) {
    this.Form.get('IMAGE').patchValue({
      KEY: event.uploaded_file_name,
      NAME: event.file_name,
      URL: `${this.imagePath}${event.uploaded_file_name}`,
      UPLOAD_STATUS: 'success'
    });
  }

  removeUploadedImage(event: any) {
    this.Form.get('IMAGE').patchValue({
      KEY: null,
      NAME: null,
      URL: null,
      UPLOAD_STATUS: null,
    });
  }

  /* once the modal is closed */
  async cancel() {
    // if (this.key.value) await this.removeImage();
    this.dialogRef.close();
  }

  /* decrease the credits from the total available credits */
  decreaseCredits() {
    this.availableCredit = this.availableCredit - 1;
  }

  /* check for form elements validations */
  checkValidation() {
    if (this.category.invalid)
      this.snackBar.open('please select a category', null, { duration: 3000, verticalPosition: 'top', horizontalPosition: 'end' });
    else if (this.question.invalid) {
      if (this.question.errors && this.question.errors.required)
        this.snackBar.open('please write a question', null, { duration: 3000, verticalPosition: 'top', horizontalPosition: 'end' });
      if (this.question.errors && this.question.errors.maxlength)
        this.snackBar.open(`question exceeded ${this.question.errors.maxlength.requiredLength} characters`, null, { duration: 3000, verticalPosition: 'top', horizontalPosition: 'end' });
    }
  }

  /* collect all the information about doubts */
  submit() {
    if (this.Form.invalid) { this.checkValidation(); return; }
    const student_id = this.authService.getCurrentStudentData() ? this.authService.getCurrentStudentData().id : null;
    const question = this.question.value;
    if (!student_id) return;
    const OBJ = {
      student_id: student_id,
      question: question,
      category: this.category.value,
      image_url: this.key.value ? [
        {
          key: this.key.value,
          name: this.name.value,
          url: this.url.value,
          upload_status: this.upload_status.value
        }
      ] : []
    }

    this.authService.addNewDoubt(OBJ).subscribe((response: any) => {
      this.decreaseCredits();
      const OBJ = {
        id: response.id,
        status: response.status,
        category: response.category,
        doubt_created_at: new Date(),
        message_events: [],
        question: this.question.value,
        unread_message: 0
      }
      this.dialogRef.close(OBJ);
    });
  }

  ngOnInit() {
  }

}
