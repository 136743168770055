import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Directive({ selector: '[keyTrack]' })

export class keyTrackDirective implements OnInit {
    type: string = null;
    isActive: boolean = false;
    @Input('keyTrack') set questionTYpe(type) {
        // console.log({ type });
        this.type = type;
    };
    @Input('isActive') set active(isActive: boolean) {
        this.isActive = [true, false].indexOf(isActive) !== -1 ? isActive : false;
        if (this.isActive === false) {
            // console.log('this instance has been destroyed', this.type, this.isActive, isActive);
            this.alive$.next('destroyed');
        }
    }

    keyPressed$ = new Subject();
    alive$ = new Subject();
    keyList: any[] = [1, 2, 3, 4];

    @HostListener('window:keydown', ['$event'])
    handleKeyDown(event: KeyboardEvent) {

        if (!this.type) return;
        if (this.isActive && this.keyList.indexOf(+event.key) !== -1)
            this.keyPressed$.next(+event.key);

        // if (this.keyList.indexOf(+event.key) !== -1)
        //     this.checkByPressed(+event.key);

    }

    constructor(private elementRef: ElementRef) { }

    ngOnInit(): void {
        this.keyPressed$.pipe(
            debounceTime(0),
            takeUntil(this.alive$)
        ).subscribe(key => {
            console.log('key pressed', key, 'isActive', this.isActive);
            this.checkByPressed(key);
        });
    }

    checkByPressed(key) {

        // console.log('checkByPressed function called');

        switch (key) {
            case 1: {
                const ID = +this.elementRef.nativeElement.id;
                if (ID === 0) {
                    this.elementRef.nativeElement.focus();
                    this.elementRef.nativeElement.click();
                }
                break;
            }
            case 2: {
                const ID = +this.elementRef.nativeElement.id;
                if (ID === 1) {
                    this.elementRef.nativeElement.focus();
                    this.elementRef.nativeElement.click();
                }
                break;
            }
            case 3: {
                const ID = +this.elementRef.nativeElement.id;
                if (ID === 2) {
                    this.elementRef.nativeElement.focus();
                    this.elementRef.nativeElement.click();
                }
                break;
            }
            case 4: {
                const ID = +this.elementRef.nativeElement.id;
                if (ID === 3) {
                    this.elementRef.nativeElement.focus();
                    this.elementRef.nativeElement.click();
                }
                break;
            }
        }
    }

}