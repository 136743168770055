import { Injectable } from '@angular/core';
import { BOTTOM_MENU_FLAG_VALUE } from '@studentapp_core/common/constant';
import { environment } from '@studentapp_env/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuGlobalDataService {

  env = environment;

  bottomMenuFlagValue = BOTTOM_MENU_FLAG_VALUE;
  actualBottomMenuHeight = 48;

  constructor(
  ) { }

  currentPageMenuSettingConfig = new BehaviorSubject<any>(null);
  currentPageMenuSettingConfig$ = this.currentPageMenuSettingConfig.asObservable();
  updateCurrentPageMenuSetting(_menu_settings: any) {
    this.currentPageMenuSettingConfig.next(_menu_settings);
  }

  showPageGlobalTopAndLeftMenu() {
    document.body.classList.add('show-top-left-bar');
  }

  hidePageGlobalTopAndLeftMenu() {
    document.body.classList.remove('show-top-left-bar');
  }

  showMobilePageBottomMenu() {
    document.body.classList.add('show-bottom-menu');
    this.vhHeight();
  }

  hideMobilePageBottomMenu() {
    document.body.classList.remove('show-bottom-menu');
    this.vhHeight();
  }

  removeMobilePageBottomMenu() {
    const _current_menu_config = this.currentPageMenuSettingConfig.value;
    if (_current_menu_config && _current_menu_config.page_menu_visibility) {
      const _bottom_menu_index = _current_menu_config.page_menu_visibility.indexOf(this.bottomMenuFlagValue);
      if (_bottom_menu_index >= 0) {
        _current_menu_config.page_menu_visibility.splice(_bottom_menu_index, 1);
        this.updateCurrentPageMenuSetting(_current_menu_config);
      }
    }
    this.hideMobilePageBottomMenu();
  }

  vhHeight() {
    let windowInnerHeight = window.innerHeight;
    const _current_menu_config = this.currentPageMenuSettingConfig.value;
    const _current_page_menu_visibility = _current_menu_config ? _current_menu_config.page_menu_visibility : [];
    if (_current_page_menu_visibility.indexOf(this.bottomMenuFlagValue) >= 0) {
      windowInnerHeight -= this.actualBottomMenuHeight;
    }
    const css = '@media screen and (max-width: 1023px){:root {--screen-vh: '+ windowInnerHeight +'px;}}';
    const head = document.getElementsByTagName('head')[0];
    const style = document.createElement('style');
    style.type = 'text/css';
    style.setAttribute("id", "vhStyle");
    style.appendChild(document.createTextNode(css));

    const myEle = document.getElementById("vhStyle");
    if(myEle){
      myEle.remove();
      head.appendChild(style);    
    } else{
      head.appendChild(style);
    }
  }

  hide_main_left_nav_auto_on_mobile(){
    document.getElementById('overlay-superbook').classList.remove('open');
    document.body.classList.add("hide-main-nav");
  }

  show_main_left_nav_auto_on_mobile() {
    document.getElementById('overlay-superbook').classList.toggle('open');
    document.body.classList.toggle('hide-main-nav');
  }

}
