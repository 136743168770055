import { Directive, Input } from '@angular/core';
import { AsyncValidator, AbstractControl, ValidationErrors, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { Observable } from 'rxjs';
import { ValidationService } from '../services/APIService/validation.service';

@Directive({
    selector: '[CheckUniquePhone]',
    providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: uniqueValidatorPhoneDirective, multi: true }]
})

export class uniqueValidatorPhoneDirective implements AsyncValidator {

    @Input('parentId') parentId: number;
    @Input('code') countryCode: string;
    constructor(private validationService: ValidationService) { }

    validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
        return new Promise((resolve, reject) => {
            this.validationService.checkParentPhone(this.countryCode, control.value, this.parentId).subscribe((response: any) => {
                if (response.isPhoneExists === true) {     /* if the parentId === 0 that means that phone not exist in DB */
                    resolve({ asyncUniquePhone: true });
                } else if (response.isPhoneValid === false) {
                    resolve({ asyncInvalidPhone: true });
                } else {
                    resolve(null);
                }
            }, (error) => {
                resolve({ serverErrorPhone: true });
            });
        });
    }
}   