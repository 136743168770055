import { AfterViewInit, Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({ selector: '[autofocus]' })
export class AutofocusDirective implements AfterViewInit, OnChanges {

    values: Array<boolean> = [true, false];
    @Input() set autofocus(condition: boolean) {

        if (this.values.indexOf(condition) !== -1)
            this.focus = condition;
        else this.focus = false;

    }

    private focus = false;

    constructor(private elementRef: ElementRef) { }

    ngOnChanges() {
        if (this.focus && this.elementRef.nativeElement) {
            this.elementRef.nativeElement.focus();
        }
    }

    ngAfterViewInit(): void {
        if (this.focus)
            this.elementRef.nativeElement.focus();
    }

}