export const WHITEBORAD_DEFAULT_WIDTH = 1366;
export const WHITEBORAD_DEFAULT_HEIGHT = 768;

export const LEARN_PAGE_RIGHT_PANEL_ID = 'learn-table-of-content-details';

export const TEACHER_DEFAULT_IMAGE = 'assets/images/user.png';
export const TEACHER_BIG_CARD_DEFAULT_IMAGE = 'assets/images/teacher-male-default-photo.png';
export const TEACHER_BIG_CARD_DEFAULT_FEMALE_IMAGE = 'assets/images/teacher-female-default-photo.png';
export const STUDENT_DEFAULT_IMAGE = 'assets/images/user.png';
export const MAIN_TOPIC_DEFAULT_IMAGE = 'assets/images/science.png';
export const ASSIGNMENT_DEFAULT_IMAGE = 'assets/images/assignment-icon.png';

export const REFER_SOURCE_SESSION_STORAGE_KEY = 'refer-source-value';
export const REFER_SOURCE_VALUE_WHITE_LiSTED = [ 'doerscore', 'aeiser' ];
export const REFER_SOURCE_PRODUCT_CATEGORY_MAP = {
    doerscore: 1,
    aeiser: 34
};

export const CLASS_STATUS_PRIORITIES = {
    'ongoing': 1,
    'not_started': 3,
    'ended': 2
};

export const DOERDO_PRODUCT_PAGE_BOTTOM_MENU_SETTING = [
    {
        id: 'doerDo',
        name: 'Learn',
        icon: 'assets/images/icons/online-learning.png',
        hover_icon: 'assets/images/icons/online-learning-fill.png',
        route_path: '/learn/home',
    },
    {
        id: 'doerDo',
        name: 'Sheet',
        icon: 'assets/images/icons/invoice.png',
        hover_icon: 'assets/images/icons/invoice-fill.png',
        route_path: '/work-sheet/listing',
    },
    {
        id: 'doerDo',
        name: 'Exam',
        icon: 'assets/images/icons/to-do-list.png',
        hover_icon: 'assets/images/icons/to-do-list-fill.png',
        route_path: '/mock-exams/listing',
    },
    {
        id: 'doerDo',
        name: 'Live Course',
        icon: 'assets/images/icons/live-class.png',
        hover_icon: 'assets/images/icons/live-class-fill.png',
        route_path: '/my-courses/home',
    },
    // {
    //     id: 'doerDo',
    //     name: 'My Courses',
    //     icon: 'assets/images/icons/invoice.png',
    //     hover_icon: 'assets/images/icons/invoice-fill.png',
    //     route_path: '/my-courses/home',
    // },
];

export const GENERAL_PRODUCT_PAGE_BOTTOM_MENU_SETTING = [
    {
        id: 'doerDo',
        name: 'Explore Courses',
        icon: 'assets/images/icons/virtual-event.png',
        hover_icon: 'assets/images/icons/virtual-event-fill.png',
        route_path: '/live-courses/home',
    },
    {
        id: 'doerDo',
        name: 'My Courses',
        icon: 'assets/images/icons/live-class.png',
        hover_icon: 'assets/images/icons/live-class-fill.png',
        route_path: '/my-courses/home',
    },
];

export type PRODUCTS_AVAILABLE = 'doerDo' | 'doerCode' | 'doerSpark' | 'doerMath' | 'doerTalk' | 'doerCampus';
export const PRODUCT_NAMES: {[key in PRODUCTS_AVAILABLE]: string} = {
    doerDo: 'DoerDo',
    doerCode: 'DoerCode',
    doerSpark: 'DoerSpark',
    doerMath: 'DoerMath',
    doerTalk: 'DoerTalk',
    doerCampus: 'DoerCampus',
};

export const PRODUCT_HEADINGS: {[key in PRODUCTS_AVAILABLE]: string} = {
    doerDo: 'Do',
    doerCode: 'Code',
    doerSpark: 'Spark',
    doerMath: 'Math',
    doerTalk: 'Talk',
    doerCampus: 'Campus',
};

export const PRODUCT_ROUTE_PATHS: {[key in PRODUCTS_AVAILABLE]: string} = {
    doerDo: 'doer-do',
    doerCode: 'doer-code',
    doerSpark: 'doer-spark',
    doerMath: 'doer-math',
    doerTalk: 'doer-talk',
    doerCampus: 'doer-campus',
};

export const THEME_ALL_CLASSES = ['theme-doerdo', 'theme-doercode', 'theme-doerspark', 'theme-doermath', 'theme-doertalk'];

export const DOERDO_PRODUCT_SETTTING = {
    product_type: 'doerDo',
    product_heading: 'doerDo',
    product_heading_html: `&nbsp;<span>doer<strong>Do</strong></span>`,
    product_route_path: 'doer-do',
    product_icon_path: 'assets/images/icons/doerdo-logo-icon.png',
    product_full_logo_path: 'assets/images/icons/product_doerScore_logo_4x.png',
    grade_id_live_course_storage_key: 'doerdo_live_course_grade_id',
    subject_id_live_course_storage_key: 'doerdo_live_course_subject_id',
    manual_product_selection_allowed: true,
    bottom_page_menu_settings: DOERDO_PRODUCT_PAGE_BOTTOM_MENU_SETTING,
    append_theme_classes: ['theme-doerdo'],
};

export const DOERCODE_PRODUCT_SETTTING = {
    product_type: 'doerCode',
    product_heading: 'doerCode',
    product_heading_html: `&nbsp;<span>doer<strong>Code</strong></span>`,
    product_route_path: 'doer-code',
    product_icon_path: 'assets/images/icons/doercode_logo_icon_121x121.png',
    product_full_logo_path: 'assets/images/icons/product_doerCode_logo_4x.png',
    grade_id_live_course_storage_key: 'doercode_live_course_grade_id',
    subject_id_live_course_storage_key: 'doercode_live_course_subject_id',
    manual_product_selection_allowed: false,
    bottom_page_menu_settings: GENERAL_PRODUCT_PAGE_BOTTOM_MENU_SETTING,
    append_theme_classes: ['theme-doercode'],
};

export const DOERSPARK_PRODUCT_SETTTING = {
    product_type: 'doerSpark',
    product_heading: 'doerSpark',
    product_heading_html: `&nbsp;<span>doer<strong>Spark</strong></span>`,
    product_route_path: 'doer-spark',
    product_icon_path: 'assets/images/icons/doerspark_logo_icon_121x121.png',
    product_full_logo_path: 'assets/images/icons/product_doerSpark_logo_4X.png',
    grade_id_live_course_storage_key: 'doerspark_live_course_grade_id',
    subject_id_live_course_storage_key: 'doerspark_live_course_subject_id',
    manual_product_selection_allowed: false,
    bottom_page_menu_settings: GENERAL_PRODUCT_PAGE_BOTTOM_MENU_SETTING,
    append_theme_classes: ['theme-doerspark'],
};

export const DOERMATH_PRODUCT_SETTTING = {
    product_type: 'doerMath',
    product_heading: 'doerMath',
    product_heading_html: `&nbsp;<span>doer<strong>Math</strong></span>`,
    product_route_path: 'doer-math',
    product_icon_path: 'assets/images/icons/doermath_logo_icon_green_121x121.png',
    product_full_logo_path: 'assets/images/icons/product_doerMath_logo_4x.png',
    grade_id_live_course_storage_key: 'doermath_live_course_grade_id',
    subject_id_live_course_storage_key: 'doermath_live_course_subject_id',
    manual_product_selection_allowed: false,
    bottom_page_menu_settings: GENERAL_PRODUCT_PAGE_BOTTOM_MENU_SETTING,
    append_theme_classes: ['theme-doermath'],
};

export const DOERTALK_PRODUCT_SETTTING = {
    product_type: 'doerTalk',
    product_heading: 'doerTalk',
    product_heading_html: `&nbsp;<span>doer<strong>Talk</strong></span>`,
    product_route_path: 'doer-talk',
    product_icon_path: 'assets/images/icons/doertalk_logo_icon_121x121.png',
    product_full_logo_path: 'assets/images/icons/product_doerTalk_logo_4x.png',
    grade_id_live_course_storage_key: 'doertalk_live_course_grade_id',
    subject_id_live_course_storage_key: 'doertalk_live_course_subject_id',
    manual_product_selection_allowed: false,
    bottom_page_menu_settings: GENERAL_PRODUCT_PAGE_BOTTOM_MENU_SETTING,
    append_theme_classes: ['theme-doertalk'],
};

export const DOERCAMPUS_PRODUCT_SETTTING = {
    product_type: 'doerCampus',
    product_heading: '',
    product_heading_html: ``,
    product_route_path: 'doer-campus',
    product_icon_path: 'assets/images/icons/doercampus_logo_icon_121x121.png',
    product_full_logo_path: 'assets/images/icons/product_doerCampus_logo_4x.png',
    grade_id_live_course_storage_key: 'doercampus_live_course_grade_id',
    subject_id_live_course_storage_key: 'doercampus_live_course_subject_id',
    manual_product_selection_allowed: false,
    bottom_page_menu_settings: GENERAL_PRODUCT_PAGE_BOTTOM_MENU_SETTING,
};

export const PRODUCT_SETTING_DATA: {[key in PRODUCTS_AVAILABLE]: any} = {
    doerDo: DOERDO_PRODUCT_SETTTING,
    doerCode: DOERCODE_PRODUCT_SETTTING,
    doerSpark: DOERSPARK_PRODUCT_SETTTING,
    doerMath: DOERMATH_PRODUCT_SETTTING,
    doerTalk: DOERTALK_PRODUCT_SETTTING,
    doerCampus: DOERCAMPUS_PRODUCT_SETTTING,
};

export const LEFT_MENU_FLAG_VALUE = 'LEFT_NAVBAR';
export const BOTTOM_MENU_FLAG_VALUE = 'BOTTOM_NAVBAR';
export const TOP_MENU_FLAG_VALUE = 'TOP_NAVBAR';

export const LIVE_COURSE_STATISTICS_ITEMS = [
    {
        name: 'Ongoing Courses',
        field: 'ongoing_courses',
        panelClass: '',
        iconPath: 'assets/images/svg/ongoing.svg',
        visible: ['LIVE_COURSE'],
        value: 0,
    },
    {
        name: 'Course Completed',
        field: 'completed_courses',
        panelClass: 'red',
        iconPath: 'assets/images/svg/completed.svg',
        visible: ['LIVE_COURSE'],
        value: 0,
    },
    {
        name: 'Class Attended',
        field: 'completed_classes',
        panelClass: 'purple',
        iconPath: 'assets/images/svg/checklist.svg',
        visible: ['LIVE_COURSE'],
        value: 0,
    },
    {
        name: 'This Week Classes',
        field: 'this_week_classes',
        panelClass: 'blue',
        iconPath: 'assets/images/svg/week-list.svg',
        visible: ['LIVE_COURSE'],
        value: 0,
    },
    {
        name: 'Open Assignments',
        field: 'open_assignments',
        panelClass: 'seafoam-green',
        iconPath: 'assets/images/svg/assignment.svg',
        visible: ['LIVE_COURSE'],
        value: 0,
    },
];

export const TOPIC_AVAILABILITIES = [
    { id: 0, name: "Active", cssClass: 'active' },
    { id: 2, name: "Backend-only", cssClass: 'warning' },
    { id: 1, name: "Inactive", cssClass: 'danger' },
];

export const TOPIC_MODES = [
    { id: 'learn', name: 'Learn' },
    { id: 'revision', name: 'Revision' },
    { id: 'test', name: 'Test' },
];

export const TOPIC_INTRO_TYPES = [
    { id: true, name: 'Show', cssClass: 'active' },
    { id: false, name: 'Hide', cssClass: 'danger' },
];

export const TOPIC_NEXT_ITEM_MAP = {
    SB: { label: 'Main Topic', cssClass: 'w-85', nextType: 'MT' },
    MT: { label: 'Sub Topic', cssClass: 'w-80', nextType: 'ST' },
    ST: { label: 'Lecture', cssClass: 'w-70', nextType: 'Lec' },
    Lec: { label: 'Lec Component', cssClass: 'w-110', nextType: 'Lec_Comp' },
};

export const TOPIC_COMPONENT_RELATED_INFO_MAP = {
    'LP': {
        items: [ 'LP' ],
    },
    'SB': {
        items: [ 'SB' ],
    },
    'MT': {
        items: [ 'MT' ],
    },
    'ST': {
        items: [ 'ST' ],
    },
    'Lec': {
        items: [ 'Lec' ],
    },
    'Lec_Comp': {
        items: [ 'TEXT', 'QUIZBANK', 'TEST', 'LESSON', 'VIDEO' ],
    },
    'QUIZBANK': {
        items: [ 'QUIZBANK' ],
    }
};

export const SKIP_ALL_KIND_RESOLVER_API_CALL = {
    skipGradesListFromBackend: true,
    skipCourseTypesListFromBackend: true,
}

export const RED_COLOR_COMBO = {
    backgroundColor: '#ffd8e1',
    textColor: '#ed345a',
};

export const GREEN_COLOR_COMBO = {
    backgroundColor: '#84f5e3',
    textColor: '#19a78d',
};

export const YELLOW_COLOR_COMBO = {
    backgroundColor: '#f4e367',
    textColor: '#fd8925',
};

export const ORANGE_COLOR_COMBO = {
    backgroundColor: '#bcdff8',
    textColor: '#1996f2',
};
