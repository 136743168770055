import { Injectable } from '@angular/core';
import { PRODUCT_SETTING_DATA } from '@studentapp_core/common/constant';
import { environment } from '@studentapp_env/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BranchProductService {
  
  env = environment;
  productBranchesConfigurations = this.env['productsConfig'];
  productsConfigurations = PRODUCT_SETTING_DATA;

  constructor() { }

  currentLiveCourseBranchId = new BehaviorSubject<any>([]);
  currentLiveCourseBranchId$ = this.currentLiveCourseBranchId.asObservable();
  updateLiveCourseBranchId(branchIds: any) {
    this.currentLiveCourseBranchId.next(branchIds);
  }

  currentLiveCourseProduct = new BehaviorSubject<string>('');
  currentLiveCourseProduct$ = this.currentLiveCourseProduct.asObservable();
  updateLiveCourseProductInfo(productName: any) {
    this.currentLiveCourseProduct.next(productName);
  }

  checkSpecificBranchIdBelongsToWhichProduct(_branch_id: number) {
    let _belongs_product_name = '';
    let _product_setting_config = null;
    for (let _product_key of Object.keys(this.productBranchesConfigurations)) {
      if (this.productBranchesConfigurations[_product_key].link_branch_ids.indexOf(_branch_id) >= 0) {
        _belongs_product_name = _product_key;
        break;
      }
    }

    if (_belongs_product_name) {
      _product_setting_config = this.productsConfigurations[_belongs_product_name];
    }
    return _product_setting_config;
  }
}
