import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalService {

  constructor() { }

  /* set selected student's data */
  setCurrentUserData(CurrentUser) {
    localStorage.setItem('CurrentUser', JSON.stringify(CurrentUser));
  }

  /* get currently logged in user's all information */
  getCurrentUser() {
    if (localStorage.getItem('CurrentUser'))
      return JSON.parse(localStorage.getItem('CurrentUser'));
    else
      return null;
  }

  /* get currently logged in user's information */
  getCurrentUserData() {
    if (localStorage.getItem('CurrentUser'))
      return JSON.parse(localStorage.getItem('CurrentUser')).user;
    else
      return null;
  }

  /* remove current user's data */
  removeCurrentUserData() {
    localStorage.removeItem('CurrentUser');
  }

  /* set selected student's data */
  setCurrentStudentData(CurrentStudent) {
    localStorage.setItem('CurrentStudent', JSON.stringify(CurrentStudent));
  }

  /* get currently logged in student information */
  getCurrentStudentData() {
    if (localStorage.getItem('CurrentStudent')) {
      return JSON.parse(localStorage.getItem('CurrentStudent'));
    }
    return null;
  }

  getCurrentStudentId() {
    if (localStorage.getItem('CurrentStudent')) {
      const _student_info = JSON.parse(localStorage.getItem('CurrentStudent'));
      return _student_info.id;
    }
    return 0;
  }

  getCurrentDoerCampusBranchInfo() {
    if (localStorage.getItem('CurrentDoerCampusBranch')) {
      return JSON.parse(localStorage.getItem('CurrentDoerCampusBranch'));
    }
    return null;
  }

  setCurrentDoerCampusBranchInfo(CurrentBranch) {
    localStorage.setItem('CurrentDoerCampusBranch', JSON.stringify(CurrentBranch));
  }

  removeCurrentDoerCampusBranchInfo() {
    localStorage.removeItem('CurrentDoerCampusBranch');
  }
}
