import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-lecture-content-alert-dialog',
  templateUrl: './lecture-content-alert-dialog.component.html',
  styleUrls: ['./lecture-content-alert-dialog.component.scss']
})
export class LectureContentAlertDialogComponent implements OnInit {

  lectureContentData: any = null;

  constructor(public dialogRef: MatDialogRef<LectureContentAlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public LectureContentData: any) {
    console.log({ LectureContentData });
    this.lectureContentData = LectureContentData ? LectureContentData : null;
  }

  ngOnInit() {
  }

}
