<div class="c-modal-body" *ngIf="lectureContentData && lectureContentData.type === 'QUIZBANK'">
  <div class="type-modal">
    <span class="ico"><img src="assets/images/quiz-icon-grey.png" alt=""></span>
    <h2>{{lectureContentData.main_topic_name}}</h2>
    <h3>{{lectureContentData.quiz_bank_title}}</h3>
    <p>{{lectureContentData.quiz_bank_descrip}}</p>
    <div class="buttonset align-center">
      <a href="javascript:void(0);" class="btn btn-sm set-button min-w-100" (click)="dialogRef.close()">Next</a>
    </div>
  </div>
</div>
<div class="c-modal-body" *ngIf="lectureContentData && lectureContentData.type === 'VIDEO'">
  <div class="type-modal">
    <span class="ico"><img src="assets/images/video-icon-grey.png" alt=""></span>
    <h2>{{lectureContentData.main_topic_name}}</h2>
    <h3>{{lectureContentData.video_title}}</h3>
    <p>{{lectureContentData.video_description}}</p>
    <div class="buttonset align-center">
      <a href="javascript:void(0);" class="btn btn-sm set-button min-w-100" (click)="dialogRef.close()">Next</a>
    </div>
  </div>
</div>
<div class="c-modal-body" *ngIf="lectureContentData && lectureContentData.type === 'TEXT'">
  <div class="type-modal">
    <span class="ico"><img src="assets/images/text-icon-grey.png" alt=""></span>
    <h2>{{lectureContentData.main_topic_name}}</h2>
    <h3>{{lectureContentData.title}}</h3>
    <p>{{lectureContentData.description}}</p>
    <div class="buttonset align-center">
      <a href="javascript:void(0);" class="btn btn-sm set-button min-w-100" (click)="dialogRef.close()">Next</a>
    </div>
  </div>
</div>