import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getStudentGrade'
})

export class GetStudentGrade implements PipeTransform {
    transform(grades: any[], grade_id?: string): void {
        console.log({ grades }, { grade_id });
        if (grades.length > 0) {
            for (let grade of grades) {
                if (grade.id === grade_id) return grade.grade_name;
            }
        }
    }
}
