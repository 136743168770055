<a href="javascript:void(0);" class="pop-close" (click)="dialogRef.close();">
  <img src="assets/images/svg/close.svg" alt="">
</a>
<div class="parent-u-details" *ngIf="currentUserData">
    <div class="parent-u-details-bx">
      <!-- <span class="parent-u-nm"><strong>Parent Name: </strong>{{ currentUserData.username | titlecase }}</span> -->
      <span class="parent-u-email"><strong>Email: </strong>{{ currentUserData.email }}</span>
      <span class="parent-u-id">Account ID: #{{ currentUserData.id }}</span>
    </div>
</div>
<div class="all-ac-list">
  <div class="ac-image-c with-check" *ngFor="let student_data of students_data ; let i = index">
    <label class="u-photo">
      <input type="radio" [checked]="currentStudent && (currentStudent.id == student_data.id)" name="u_list" (change)="getSeletedStudent(i)" [disabled]="isDisabled">
      <img src="assets/images/user.png" alt="" *ngIf="!(student_data.avatar_link)">
      <img [src]="imagePath + student_data.avatar_link" alt="" *ngIf="(student_data.avatar_link)">
      <span class="change">
        <img src="assets/images/svg/tick.svg" alt="">
      </span>
    </label>
    <span class="nm">{{ (student_data.nick_name ? student_data.nick_name : student_data.name) | titlecase }}</span>
    <span class="s-id">ID: #{{ student_data.id }}</span>
    <span class="subc" *ngIf="((student_data?.student_subscription | json) == '{}') ||
                               (student_data?.student_subscription?.status === 'cancelled')">(No Subscription)</span>
  </div>
  <div class="ac-image-c with-check" *ngIf="students_data.length < 5" (click)="addNewStudent()">
    <label class="u-photo">
      <span class="add-user">
        <img src="assets/images/svg/add-black.svg" alt="">
      </span>
    </label>
    <span class="nm">Add Student</span>
  </div>
</div>