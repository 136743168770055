import { Component, OnDestroy, OnInit, Input, Output, EventEmitter, OnChanges } from "@angular/core";
import { CommonService } from "../../../app/core/services/common.service";

@Component({
  selector: 'timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit, OnChanges, OnDestroy {

  @Input('timeInSeconds') set time(seconds: number) {
    this.seconds = seconds;
  };
  @Input('action') set action(value: string) {
    this.actionValue = value;
    // if (value === 'PAUSE') this.clearTimer();
    // if (value === 'START' && this.seconds > 0) this.countDown();
  };
  @Input('formatIn') set formatIn(value: string) {
    console.log({ value });
    if (value) this.timeIn = value;
    else this.timeIn = "HOURS";
  };

  @Output('onComplete') onComplete = new EventEmitter();
  @Output('notify') notify = new EventEmitter();

  intervalId;
  message = "";
  seconds;
  actionValue: string;
  timeIn = "";

  constructor(private commonService: CommonService) { }

  clearTimer() {
    clearInterval(this.intervalId);
  }

  countDown() {
    this.clearTimer();
    this.intervalId = setInterval(() => {
      this.seconds -= 1;
      this.timer();
    }, 1000);
  }


  /* timer() {
    let s: any = 0, m: any = 0, h: any = 0;
    s = this.seconds % 60;
    s = s > 9 ? s : `0${s}`;
    m = Math.floor(this.seconds / 60);
    if (m > 59) {
      h = Math.floor(m / 60);
      m = Math.floor(m % 60);
    }
    m = m > 9 ? m : `0${m}`;
    h = h > 9 ? h : `0${h}`;
    if (this.seconds <= 0) {
      this.message = `${h}:${m}:${s}`;
      this.onComplete.emit();
      this.clearTimer();
    } else {
      this.message = `${h}:${m}:${s}`;
    }
    this.notify.emit(this.seconds);
  } */

  timer() {
    let timerObj = this.commonService.calculation(this.seconds, this.timeIn);
    if (this.seconds <= 0) {
      this.message = timerObj.format;
      this.onComplete.emit();
      this.clearTimer();
    } else {
      this.message = timerObj.format;
    }
    this.notify.emit(this.seconds);
  }

  ngOnChanges() {
    if (this.actionValue === 'PAUSE') this.clearTimer();
    if (this.actionValue === 'START' && this.seconds > 0) {
      this.countDown();
    } 
  }

  ngOnInit() {
    let timerObj = this.commonService.calculation(this.seconds, this.timeIn);
    this.message = timerObj.format;
  }

  ngOnDestroy() {
    this.clearTimer();
  }


}
