import { Directive, Input } from '@angular/core';
import { AsyncValidator, AbstractControl, ValidationErrors, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { Observable } from 'rxjs';
import { ValidationService } from '../services/APIService/validation.service';

@Directive({
    selector: '[CheckUniqueEmail]',
    providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: uniqueValidatorEmailDirective, multi: true }]
})

export class uniqueValidatorEmailDirective implements AsyncValidator {

    @Input('parentId') parentId: number;
    constructor(private validationService: ValidationService) { }

    validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
        return new Promise((resolve, reject) => {
            this.validationService.checkParentEmail(control.value, this.parentId).subscribe((data: any) => {
                if ((data.message == true)) {     /* if the parentId === 0 that means that email not exist in DB */
                    resolve({ asyncUniqueEmail: true })
                } else {
                    resolve(null)
                }
            }, (error) => {
                resolve({ serverErrorEmail: true })
            });
        });
    }
}   