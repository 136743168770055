import { Component, Input, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ProductSwitcherBottomSheetComponent } from '../product-switcher-bottom-sheet/product-switcher-bottom-sheet.component';

@Component({
  selector: 'app-bottom-navbar-default',
  templateUrl: './bottom-navbar-default.component.html',
  styleUrls: ['./bottom-navbar-default.component.scss']
})
export class BottomNavbarDefaultComponent implements OnInit {

  @Input() subRoutePath = '';
  @Input() bottomMenuItemsList: any = [];
  @Input() activeProductSetting: any = null;

  constructor(
    private _bottomSheet: MatBottomSheet
  ) {}

  openProductSwitcherPopup() {
    this._bottomSheet.open(ProductSwitcherBottomSheetComponent, {
      panelClass: 'product-switch-modal',
      backdropClass: 'product-switch-overlay'
    });
  }

  ngOnInit(): void {
  }

}
