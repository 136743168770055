import { Injectable } from '@angular/core';
import { REFER_SOURCE_PRODUCT_CATEGORY_MAP, REFER_SOURCE_SESSION_STORAGE_KEY, REFER_SOURCE_VALUE_WHITE_LiSTED } from '../common/constant';
import { HttpClient } from '@angular/common/http';
import { environment } from '@studentapp_env/environment';
import { AuthService } from './APIService/auth.service';
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReferalSourceDetectService {

  BASE_URL = environment.base_url;
  referalSrcSessionStorage = REFER_SOURCE_SESSION_STORAGE_KEY;
  whitelistedReferalSources = REFER_SOURCE_VALUE_WHITE_LiSTED;
  referSourceProductCategoryMap = REFER_SOURCE_PRODUCT_CATEGORY_MAP;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
  ) { }

  getReferalSourceValueFromSessionStorage(): any {
    if (localStorage.getItem(this.referalSrcSessionStorage)) {
      return JSON.parse(localStorage.getItem(this.referalSrcSessionStorage));
    }
    return null;
  }

  setReferalSourceValueInSessionStorage(value: any): void {
    localStorage.setItem(this.referalSrcSessionStorage, JSON.stringify(value));
  }

  removeReferalSourceValueFromSessionStorage(): void {
    localStorage.removeItem(this.referalSrcSessionStorage);
  }

  checkSourceKeyIsWhiteListed(key: string): boolean {
    return this.whitelistedReferalSources.indexOf(key.toLowerCase()) >= 0;
  }

  findExistingSourceAvailableUnProcessed(stored_source_value: any, sourceKey: string): any {
    let is_exists = null;
    is_exists = stored_source_value.find((referalSource) => referalSource.sourceKey === sourceKey.toLowerCase());
    return is_exists;
  }

  createSourceObject(source: any): any {
    const _source_key = source.sourceKey.toLowerCase();
    const _new_source_value = {
      id: Date.now(),
      sourceKey: _source_key,
      product_category: this.referSourceProductCategoryMap[_source_key],
      captured_at: Date.now(),
    };
    return _new_source_value;
  }

  UpdateReferalSourceValueInSessionStorage(value: any): void {
    // console.log('this.checkSourceKeyIsWhiteListed(value.sourceKey)', this.checkSourceKeyIsWhiteListed(value.sourceKey))
    if (!this.checkSourceKeyIsWhiteListed(value.sourceKey)) {
      return;
    }
    let _stored_value = this.getReferalSourceValueFromSessionStorage();
    if (_stored_value) {
      if (!this.findExistingSourceAvailableUnProcessed(_stored_value, value.sourceKey)) {
        _stored_value.push(this.createSourceObject(value));
      }
    }
    else {
      _stored_value = [ this.createSourceObject(value) ];
    }
    this.setReferalSourceValueInSessionStorage(_stored_value);
  }

  executeAllUnProcessedReferalSources(_user_data: any = null) {
    let _current_user_data = this.authService.getCurrentUserData();
    // console.log('CURRENT STUDENT DATA', _current_user_data);
    if (!_current_user_data) {
      _current_user_data = _user_data;
    }
    if (_current_user_data) {
      let _stored_value = this.getReferalSourceValueFromSessionStorage();
      // console.log('_stored_value', JSON.stringify(_stored_value));
      if (_stored_value) {
        const _executing_stored_value = _stored_value.filter((referalSource) => (!referalSource.processed_at && this.whitelistedReferalSources.indexOf(referalSource.sourceKey) >= 0));
        // console.log('_executing_stored_value', JSON.stringify(_executing_stored_value));
        if (_executing_stored_value.length) {
          let apiReqs = [];
          _executing_stored_value.forEach((referalSource) => {
            const _api_payload_obj = {
              account_id: _current_user_data.id,
              product_category: referalSource.product_category,
            }
            apiReqs.push(this.storeReferalSourceInDatabase(_api_payload_obj));
          });
          // console.log('APIREQS', apiReqs.length)

          forkJoin(apiReqs).subscribe(
            (response: any) => {
              _executing_stored_value.forEach((referalSource, index) => {
                referalSource.processed_at = Date.now();
              });
              console.log('ALL RECORD SUBMITTED');
              this.setReferalSourceValueInSessionStorage(_stored_value);
            },
            (error: any) => {

            }
          )
        }
      }
    }
  }

  storeReferalSourceInDatabase(referalSource: any) {
    return this.httpClient.post(`${this.BASE_URL}/v1/register/users_product_category`, referalSource);
  }
}
