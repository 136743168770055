<div class="main-wrapper">
    <div class="page-header-c">
        <div class="head-l">
            <div class="menuBarIco" *ngIf="showMainNavFeature" (click)="show_main_nav()">
                <span class="line"></span>
            </div>
            <button type="button" class="math-back-btn sm no-box-shadow float-left" *ngIf="showBackPreviousPageFeature" (click)="backPreviousPage()">
                <i class="fa fa-angle-left"></i> Back
            </button>
        </div>
        <div class="head-m">
            <div class="h-logo">
                <img [src]="productLogoPath ? productLogoPath : '/assets/images/icons/doerdo-logo-left-panel.png'" alt="">
            </div>
        </div>
        <div class="head-r">
            <a class="btn btn-purple btn-xs login-btn" [routerLink]="['', { outlets: { modal: 'student/login' }}]" [queryParams]="loginRouteQueryParams" [queryParamsHandling]="loginRouteQueryParamsHandling" [state]="resolverSkipObjectData">
                <img src="./assets/images/svg/login-icon.svg" alt="">
                Login
            </a>
            <a class="btn btn-purple btn-xs login-btn signup" style="margin-left: 6px;" [routerLink]="['', { outlets: { modal: 'student/registration' }}]" [queryParamsHandling]="'preserve'" [state]="resolverSkipObjectData">
                <img src="./assets/images/svg/user-plus.svg" alt="">
                Sign up
            </a>
        </div>
    </div>
</div>