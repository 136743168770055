import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { PRODUCT_SETTING_DATA } from '@studentapp_core/common/constant';
import { BranchProductService } from '@studentapp_core/services/branch-product.service';
import { environment } from '@studentapp_env/environment';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BranchProductConfiguratorResolver implements Resolve<any> {

  env = environment;
  productBranchesConfigurations = this.env['productsConfig'];
  productsConfigurations = PRODUCT_SETTING_DATA;

  parentParentBranchLevelPageTypes = ['LEARN', 'SUPERBOOK', 'MOCK_EXAM', 'WORK_SHEET', 'ASK_DOUBT', 'LIVE_COURSE', 'LIVE_MY_COURSE', 'LIVE_ENROLLED_COURSE', 'LIVE_PUBLIC_COURSE', 'ONLINE_CLASSROOM', 'CALENDAR', 'ANNOUCEMENTS', 'CHANNEL_COMMUNICATION'];

  constructor(
    private branchProductService: BranchProductService,
  ) {
  }

  getProductLevelForLiveCourse(route: ActivatedRouteSnapshot) {
    console.log('route', route);
    let _product_level = '';
    if (route.parent?.parent?.data) {
      _product_level = route.parent.parent.data.branchLevel;
    }
    return _product_level;
  }

  /**
   * Returns the product configuration information based on the route and state information
   * @param route ActivatedRouteSnapshot
   * @param state RouterStateSnapshot
   * @returns product configuration information
   */
  getProductConfigurationFromRoute(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const _product_config_info = {
      product_setting: null,
      product_level: '',
      current_main_url: '',
      current_full_url: '',
      grade_subject_data: null,
      course_types_data: null,
    };

    if (state.url) {
      _product_config_info.current_full_url = state.url;
      _product_config_info.current_main_url = state.url.split('?')[0];
    }

    let _product_level = '';
    let _product_setting: any = null;

    console.log('route 1', route);


    if (this.parentParentBranchLevelPageTypes.indexOf(route.data.pageType) >= 0) {
      _product_level = this.getProductLevelForLiveCourse(route);
    }

    if (_product_level) {
      _product_config_info.product_level = _product_level;
      if (_product_level in this.productsConfigurations) {
        _product_setting = this.productsConfigurations[_product_level];
        _product_config_info.product_setting = Object.assign(_product_setting, this.productBranchesConfigurations[_product_level]);
      }
    }
    return _product_config_info;
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const _product_config_info = this.getProductConfigurationFromRoute(route, state);
    console.log('BRANCH PRODUCT RESLVER ', _product_config_info);
  
    this.branchProductService.updateLiveCourseProductInfo(_product_config_info);
  
    return of(_product_config_info);
  }
}
