import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
@Directive({ selector: '[enterToSubmit]' })

export class EnterToSubmitDirective implements OnInit {
    enterPressed$ = new Subject();
    @HostListener('window:keydown.enter', ['$event'])
    clickBtn(event: KeyboardEvent) { 
        event.preventDefault(); 
        if (!this.isButtonDisabled) this.enterPressed$.next();
    }

    get isButtonDisabled(): boolean {
        let isDisabled = true;
        if (this.elementRef) {
            isDisabled = this.elementRef.nativeElement && this.elementRef.nativeElement.disabled ? true : false;
            return isDisabled;
        }

        return isDisabled;
    }

    constructor(private elementRef: ElementRef) { }
 
    ngOnInit(): void {
        this.enterPressed$.pipe(
            debounceTime(1000),
            distinctUntilChanged(),
            take(1)
        ).subscribe(_ => {
            this.elementRef.nativeElement.click();
        });
    }
}