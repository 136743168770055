import { Directive, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
    selector: '[ChangeHttpsToHttp]'
})

export class ChangeHttpsToHttpDirective implements AfterViewInit {

    regex1 = /<img src="https:/
    regex2 = /\.[a-z]{3,4}">/

    constructor(private el: ElementRef) { }

    ngAfterViewInit(): void {
        // console.log(this.el);
        let html = this.el.nativeElement.innerHTML;
        if (this.regex1.test(html)) {
            html = html.replace(this.regex1, `<img src="`);
            console.log(html);
            // html = html.replace(this.regex2, ``);
            this.el.nativeElement.innerHTML = html;
            // console.log('MATCH', this.el.nativeElement.innerHTML);
        }
    }


}   