import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'checkMainQuestion'
})

export class CheckMainQuestionPipe implements PipeTransform {
    transform(section: any, args?: string): Boolean {
        if (section && section.allsections.length > 0 && section.allsections[0].type === 'QUESTION' && section.allsections[0].questionCategory === 'MAIN-QUESTION') {
            return true;
        } else {
            return false;
        }
    }
}
