import { Directive, ElementRef, Input, OnDestroy, OnInit } from "@angular/core";

/**
declare global {
  interface Window {
    com: {
      wiris: {
        js: {
          JsPluginViewer: {
            parseElement: (domElement: HTMLElement, async: boolean) => void
          }
        }
      }
    };
  }
}
*/
declare const MathJax: any;

@Directive({
  selector: '[appMath]'
})
export class MathDirective implements OnInit, OnDestroy {
  @Input() private appMath: string;
  private readonly el: any;

  constructor(
    private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
    // this.render();
    this.renderMathJaxV2();
  }

  /**
  private render() {
    if(this.appMath){
      this.elementRef.nativeElement.innerHTML = this.appMath;
      window.com.wiris.js.JsPluginViewer.parseElement(this.elementRef.nativeElement, true);
    }
    else {
      this.elementRef.nativeElement.innerHTML = `<p>Information doesn't exist</P>`;
    }

  }
  */

  private renderMathJaxV2() {
    if(this.appMath){
      this.elementRef.nativeElement.innerHTML = this.appMath;
      MathJax.Hub.Queue(["Typeset", MathJax.Hub, this.elementRef.nativeElement]);
    }
    else {
      this.elementRef.nativeElement.innerHTML = `<p>Information doesn't exist</P>`;
    }
  }

  // extractMathSvgMLExpressions(paragraph) {
  //   const regex = /<math[^>]*>([\s\S]*?)<\/math>/gm;

  //   return paragraph.replace(regex, (match) => {
  //     let latex = MathJax.mathml2svg(match);
  //     return latex.outerHTML;
  //   });
  // }

  // private renderMathJaxV3() {
  //   if(this.appMath){
  //     const exprs = this.extractMathSvgMLExpressions(this.appMath);
  //     this.elementRef.nativeElement.innerHTML = exprs;
  //   }
  //   else {
  //     this.elementRef.nativeElement.innerHTML = `<p>Information doesn't exist</P>`;
  //   }
  // }

  ngOnDestroy() {

  }

}
