import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/APIService/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private authService: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute,) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (this.authService.getCurrentUser()) {
            return true;
        }

        // this.router.navigate(['/student/login']);
        this.router.navigate(['', { outlets: { modal: 'student/login' } }], { queryParamsHandling: 'preserve', relativeTo: this.activatedRoute});
        return false;

    }
}