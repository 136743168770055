import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getStudentExamDate'
})

export class GetStudentExamDate implements PipeTransform {
    transform(exam_dates: any[], exam_dates_id?: string): void {
        console.log({ exam_dates }, { exam_dates_id });
        if (exam_dates.length > 0) {
            for (let exam_date of exam_dates) {
                if (exam_date.id === exam_dates_id) return exam_date.name;
            }
        }
    }
}
