import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SKIP_ALL_KIND_RESOLVER_API_CALL } from '@studentapp_core/common/constant';

@Injectable()
export class AccountManagementGuard implements CanActivate {

    resolverSkipObjectData = SKIP_ALL_KIND_RESOLVER_API_CALL;
    
    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (sessionStorage.getItem('isUserAuthenticated')) {
            return true;
        }

        this.router.navigate(['', { outlets: { modal: 'management/account/login' } }], { queryParamsHandling: 'preserve', state: this.resolverSkipObjectData });
        return false;

    }
}