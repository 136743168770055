import { Injectable } from '@angular/core';
import { DataService } from './DataService/data.service';
import { LocalService } from './local.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '@studentapp_env/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  env = environment;
  private BASE_URL = environment.base_url;

  doerCampusBranchesFetchStatus = new BehaviorSubject<any>({ status: 'ON_INIT' });
  doerCampusBranchesFetchStatus$ = this.doerCampusBranchesFetchStatus.asObservable();
  updateDoerCampusBranchesFetchStatusInfo(statusName: any) {
    this.doerCampusBranchesFetchStatus.next(statusName);
  }

  constructor(
    private http: HttpClient,
    private dataService: DataService,
    private localService: LocalService,
  ) { }

  setDoerCampusBranchIdsInEnvironment(_branch_id: number) {
    this.env.productsConfig.doerCampus.link_branch_ids = [_branch_id];
  }

  clearDoerCampusBranchIdsInEnvironment() {
    this.env.productsConfig.doerCampus.link_branch_ids = [];
  }

  updateCurrentUserAndStudentDetails(_user_info: any) {
    let CurrentUser = this.localService.getCurrentUser();
    let _students_data = _user_info && _user_info.student_data ? _user_info.student_data : [];
    CurrentUser.process.students = _students_data;
    this.localService.setCurrentUserData(CurrentUser);
    this.updateCurrentStudentDetails(_students_data);
    this.dataService.update_storage(true);
  }

  updateCurrentStudentDetails(_students_data) {
    let CurrentStudentId = this.localService.getCurrentStudentId();
    const currentStudentData = _students_data.find((_stu_info: any) => _stu_info.id === CurrentStudentId);
    if (currentStudentData)  {
      this.updateCurrentDoerCampusBranchDetail(currentStudentData);
      this.localService.setCurrentStudentData(currentStudentData);
    }
  }

  updateCurrentDoerCampusBranchDetail(currentStudentData: any) {
    const CurrentDoerSchoolBranchInfo = this.localService.getCurrentDoerCampusBranchInfo();
    let _new_doer_school_branch_info = null;
    if (currentStudentData.branch_data && currentStudentData.branch_data.length) {
      if (CurrentDoerSchoolBranchInfo) {
        const _active_branch_matched = currentStudentData.branch_data.find((_each_branch) => _each_branch.id === CurrentDoerSchoolBranchInfo.id);
        if (!_active_branch_matched) {
          _new_doer_school_branch_info = currentStudentData.branch_data[0];
        }
        else {
          _new_doer_school_branch_info = _active_branch_matched;
        }
      }
      else {
        _new_doer_school_branch_info = currentStudentData.branch_data[0];
      }
    }
    if (_new_doer_school_branch_info) {
      this.localService.setCurrentDoerCampusBranchInfo(_new_doer_school_branch_info);
      this.setDoerCampusBranchIdsInEnvironment(_new_doer_school_branch_info.id);
    }
    else {
      this.localService.removeCurrentDoerCampusBranchInfo();
      this.clearDoerCampusBranchIdsInEnvironment();
    }
  }

  /* get loggedin user's data from the DB */
  getUserDetails(user_id) {
    let OBJ = {
      account_id: user_id
    }
    return this.http.post(`${this.BASE_URL}/register/user-details/`, OBJ);
  }

  updateStudentInformationInStorage() {
    this.updateStudentUserDetails();
  }

  /* update student data in local storage everytime the A/C management popup closed */
  updateStudentUserDetails() {
    let CurrentUser = this.localService.getCurrentUser();
    let user_id = CurrentUser.user.id ? CurrentUser.user.id : null;
    if (!user_id) return;
    this.getUserDetails(user_id).subscribe(
      (data: any) => {
        if (!data.isError) {
          this.updateCurrentUserAndStudentDetails(data.accountInfo);
        }
      },
      (error) => console.error(error)
    );
  }
}
