import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BOTTOM_MENU_FLAG_VALUE, LEFT_MENU_FLAG_VALUE, TOP_MENU_FLAG_VALUE } from '@studentapp_core/common/constant';
import { BranchProductService } from '@studentapp_core/services/branch-product.service';
import { MenuGlobalDataService } from '@studentapp_core/services/menu-global-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-page-display-structure',
  templateUrl: './page-display-structure.component.html',
  styleUrls: ['./page-display-structure.component.scss']
})
export class PageDisplayStructureComponent implements OnInit, OnDestroy {

  @Input() productInfo: string = '';

  bottomMenuFlagValue = BOTTOM_MENU_FLAG_VALUE;
  leftMenuFlagValue = LEFT_MENU_FLAG_VALUE;
  topMenuFlagValue = TOP_MENU_FLAG_VALUE;

  isHidden: boolean = true;
  userLoginHidden = true;

  productLogoPath = '';
  subRoutePath = '';
  activeProductSetting = null;

  pageMenuVisibility: any[] = [];
  currentPageBottomMenuItemList = [];

  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private branchProductService: BranchProductService,
    private menuGlobalDataService: MenuGlobalDataService,
  ) {
    this.listenForRouterEvents();
    this.listenForBranchProductRelatedChanges();
    this.listenForPageMenuRelatedChanges();
  }

  handleBottomMenuVisibility() {
    if (this.pageMenuVisibility.indexOf(this.bottomMenuFlagValue) >= 0) {
      this.menuGlobalDataService.showMobilePageBottomMenu();
    }
    else {
      this.menuGlobalDataService.hideMobilePageBottomMenu();
    }
  }

  handleLeftAndTopMenuVisibility() {
    if (this.pageMenuVisibility.indexOf(this.topMenuFlagValue) >= 0 || this.pageMenuVisibility.indexOf(this.leftMenuFlagValue) >= 0) {
      this.isHidden = false;
      this.userLoginHidden = false;
      this.menuGlobalDataService.showPageGlobalTopAndLeftMenu();
    }
    else {
      this.isHidden = true;
      this.userLoginHidden = true;
      this.menuGlobalDataService.hidePageGlobalTopAndLeftMenu();
    }
  }

  listenForRouterEvents() {
    this.subscriptions.push(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          console.log({ event });
          const _menu_config_info = this.menuGlobalDataService.currentPageMenuSettingConfig.value;
          this.pageMenuVisibility = (_menu_config_info && _menu_config_info.page_menu_visibility) ? _menu_config_info.page_menu_visibility : [];
          this.handleBottomMenuVisibility();
          this.handleLeftAndTopMenuVisibility();
        }
      })
    );
  }

  _listenForRouterEvents() {
    this.subscriptions.push(
      this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        console.log({ event });
        if (event.urlAfterRedirects === '/superbooks') {
          this.isHidden = false;
          this.userLoginHidden = false;
        } else if (event.urlAfterRedirects.indexOf('/superbooks/list') >= 0 || event.urlAfterRedirects.indexOf('/mock-exams/listing') >= 0 || event.urlAfterRedirects.indexOf('/assessments/list') >= 0 || event.urlAfterRedirects.indexOf('/work-sheet/listing') >= 0) {
          this.isHidden = false;
          this.userLoginHidden = false;
        } else if (event.urlAfterRedirects.indexOf('/live-courses/course-preview/') >= 0) {
          this.isHidden = true;
          this.userLoginHidden = false;
        } else if (event.urlAfterRedirects.includes('/feature-support') || event.urlAfterRedirects.includes('/ask-doubt') || event.urlAfterRedirects.includes('/management') || event.urlAfterRedirects.includes('/student') || event.urlAfterRedirects.includes('/catalogue') || event.urlAfterRedirects.includes('/learn') || event.urlAfterRedirects.includes('/live-courses') || event.urlAfterRedirects.includes('/my-courses') || event.urlAfterRedirects.includes('/enrolled-course') || event.urlAfterRedirects.includes('/course-path-design') || event.urlAfterRedirects.includes('/doergrade-design') || event.urlAfterRedirects.includes('/course-detail-design') ) {
          this.isHidden = false;
          this.userLoginHidden = false;
        } else {
          this.isHidden = true;
          this.userLoginHidden = true;
        }
        if(this.isHidden) {
          document.body.classList.remove('show-top-left-bar');
        }
        else {
          document.body.classList.add('show-top-left-bar');
        }
      }
    }));
  }

  listenForBranchProductRelatedChanges() {
    this.subscriptions.push(
      this.branchProductService.currentLiveCourseProduct$.subscribe(
        (response: any) => {
          if (response && response.product_setting) {
            this.activeProductSetting = response.product_setting;
            this.productLogoPath = response.product_setting.product_full_logo_path;
            this.subRoutePath = response.product_setting.product_route_path;
            this.currentPageBottomMenuItemList = response.product_setting.bottom_page_menu_settings;
          }
        }
      )
    )
  }

  listenForPageMenuRelatedChanges() {
    this.subscriptions.push(
      this.menuGlobalDataService.currentPageMenuSettingConfig$.subscribe(
        (response: any) => {
          // this.pageMenuVisibility = (response && response.page_menu_visibility) ? response.page_menu_visibility : [];
        }
      )
    )
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

}
