import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LeftMenuHandlerService {

  constructor() { }

  showSecondLevelLeftMenu(classesList = []) {
    document.getElementById('left-main-first-menu-bar').classList.add('inactive');
    document.getElementById('left-main-second-menu-bar').classList.remove('inactive');
    if (classesList.length) {
      this.setClassForLeftMenuWrapper(classesList);
    }
  }

  hideSecondLevelLeftMenu(classesList = []) {
    document.getElementById('left-main-first-menu-bar')?.classList.remove('inactive');
    document.getElementById('left-main-second-menu-bar')?.classList.add('inactive');
    if (classesList.length) {
      this.removeClassForLeftMenuWrapper(classesList);
    }
  }

  setClassForLeftMenuWrapper(classesList: any[]) {
    // document.getElementById('left-menu-sidebar-wrapper').classList.add(...classesList);
  }

  removeClassForLeftMenuWrapper(classesList: any[]) {
    // document.getElementById('left-menu-sidebar-wrapper').classList.remove(...classesList);
  }

  showLeftMenuHeadBreadcrumbSection() {
    document.getElementById('left-main-menu-head-breadcrumb-section').classList.remove('inactive');
  }

  hideLeftMenuHeadBreadcrumbSection() {
    document.getElementById('left-main-menu-head-breadcrumb-section').classList.add('inactive');
  }

  activeSecondLeftMenu(classesList = []) {
    this.showSecondLevelLeftMenu(classesList);
    this.showLeftMenuHeadBreadcrumbSection();
  }

  inActiveSecondLeftMenu(classesList = []) {
    this.hideSecondLevelLeftMenu(classesList);
    this.hideLeftMenuHeadBreadcrumbSection(); 
  }

  setLastVisitedMenuLevelInSessionStorage(menuLevel: string) {
    sessionStorage.setItem('lastVisitedMenuLevel', menuLevel);
  }
  
  getLastVisitedMenuLevelFromSessionStorage() {
    if (sessionStorage.getItem('lastVisitedMenuLevel')) {
      return sessionStorage.getItem('lastVisitedMenuLevel');
    }
    return null;
  }

  deleteLastVisitedMenuLevelFromSessionStorage() {
    sessionStorage.removeItem('lastVisitedMenuLevel');
  }

}
