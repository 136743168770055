<div class="error-msg-dialog">
    
  <div class="not-found-container"  *ngIf="error.status === 404">
      <div class="error-inner">
      <p class="text-center">
          <img class="error-image" src="../../assets/images/svg/404.svg" alt="icon"/>
      </p>
      <h3> Requested Resource Not Found!</h3>
      <p>something unexpected happened.<br/>the above error occurred while the web server was processing your request.</p>
  </div>
  </div>

  <div class="not-found-container"   *ngIf="error.status === 500">
    <div class="error-inner">
          <p class="text-center">
              <img class="error-image" src="../../assets/images/svg/download.svg" alt="icon"/>
          </p>
          <h3>  Server Not Responding!</h3>
          <p>sorry, something went wrong.<br>we're working on it and we'll get it fixed as soon as we can.</p>
    </div>
  </div>

  <div class="not-found-container"   *ngIf="error.status === 401">
    <div class="error-inner">
          <p class="text-center">
              <img class="error-image" src="../../assets/images/svg/warning.svg" alt="icon"/>
          </p>
          <h3>   Session Timeout!</h3>
          <p>your session has expired. <br>please login again to continue working.</p>
    </div>
  </div>
</div>
