<div class="submitAnswerModal" *ngIf="dialogData.action === 'submited'">
  <div class="headr-line">&nbsp;</div>
  <div class="body-sec with-scroll">
    <mat-dialog-content>
      <div class="dialog-content">
        <h2><span>{{dialogData.studentName}}</span> are you sure to submit your answer?</h2>
        <div class="bodyRw">
          <div class="col-R">
            <div class="infoLi">
              <div class="li">You answered <span>{{dialogData.givenAns}}</span></div>
              <div class="li">Questions out of <span>{{dialogData.totalQuestion}}</span></div>
            </div>
            <div class="infoLi2">
              <div class="li"  *ngIf="dialogData.givenTime"><span>Time given:</span> {{givenTime}}</div>
              <div class="li" *ngIf="dialogData.timeLeft"><span>Time Left:</span> {{ timeLeft }} </div>
              <div class="li" *ngIf="!dialogData.timeLeft && dialogData.timeTaken"><span>Time Taken:</span> {{ timeTaken }} </div>
            </div>
            <div class="buttonset">
              <button class="n-btn n-btn-light m-0" (click)=" dialogRef.close();">No, let me check</button>
              <button class="n-btn ml-3" (click)="dialogRef.close('submited');">Yes Submit</button>
            </div>
          </div>
          <div class="col-L">
            <img src="assets/images/bg-submit-question.png" alt=""/>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </div>
</div>

<timer  [action]="'START'" 
        [timeInSeconds]="dialogData.timeLeft" 
        [formatIn]="'HOURS'"
        (notify)="getTime($event)" 
        [hidden]="true" 
        *ngIf="dialogData.givenTime !== null">
</timer>

<div class="submitAnswerModal" *ngIf="dialogData.action === 'time_over'">
  <div class="headr-line">&nbsp;</div>
  <div class="body-sec pb-4">
    <h2><span>{{dialogData.studentName}}</span> your given time is over!</h2>
    <div class="m-auto-c">
        <div class="buttonset m-0">
          <button class="n-btn n-btn-primary m-0" (click)="dialogRef.close('time_over');">Check My Results</button>
        </div>
    </div>
    <div class="modal-bottom-img">
      <img src="assets/images/watch2.png" alt="" style="width:216px; max-width:100%;">
    </div>
  </div>
</div>


<div class="submitAnswerModal" *ngIf="dialogData.action === 'quit'">
  <div class="headr-line">&nbsp;</div>
  <div class="body-sec">
    <h2>Are you sure you want to quit this test, <span>{{dialogData.studentName}}</span>?</h2>
    <div class="m-auto-c">
      <div class="infoLi justify-content-center">
        <div class="li">You answered <span>{{dialogData.givenAns}}</span></div>
        <div class="li">Questions out of <span>{{dialogData.totalQuestion}}</span></div>
      </div>
      <div class="buttonset2">
        <button class="n-btn n-btn-danger m-0" (click)=" dialogRef.close();">No</button>
        <button class="n-btn n-btn-success ml-3" (click)="dialogRef.close('quit');">Yes</button>
      </div>
      <div class="modal-bottom-img">
        <img src="assets/images/characters.png" alt="">
      </div>
    </div>
  </div>
</div>


<div class="submitAnswerModal" *ngIf="dialogData.action === 'retake'">
  <div class="headr-line">&nbsp;</div>
  <div class="body-sec pb-4">
    <h2><span>{{dialogData.studentName}}</span> are you ready retake this test?</h2>
    <div class="m-auto-c">
        <div class="buttonset m-0">
          <button class="n-btn n-btn-danger m-0" (click)=" dialogRef.close();">No</button>
          <button class="n-btn n-btn-success ml-3" (click)="dialogRef.close('retake');">Yes</button>
        </div>
        <div class="modal-bottom-img">
          <img src="assets/images/watch.png" alt="" style="width:216px; max-width:100%;">
        </div>
    </div>
  </div>
</div>