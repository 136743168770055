import { Injectable } from '@angular/core';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SegmentAnalyticsService {

    env = environment;
    analytics: AnalyticsBrowser = null;

    constructor() {
    }

    load() {
        this.analytics = AnalyticsBrowser.load({ writeKey: this.env.segmentAnalytics.writeKey });
        console.log(this.analytics);
    }

    createIdentityInformation(userInfo: any, studentInfo: any, actionFrom = 'LOGIN') {
        let payloadObj = {
            account_id: userInfo.user.id,
            parent_name: userInfo.user.username,
            number_of_students: userInfo?.process?.students.length,
            email: userInfo.user.email,
            actionFrom: actionFrom
        };

        if (userInfo.process && userInfo.process.students) {
            const index = userInfo.process.students.findIndex((stu) => (stu.id === studentInfo.id));
            if (index >= 0) {
                payloadObj[`stu${index+1}_birthday`] = studentInfo.date_of_birth;
                payloadObj[`stu${index+1}_gender`] = studentInfo.gender;
                payloadObj[`stu${index+1}_id`] = studentInfo.id;
                payloadObj[`stu${index+1}_name`] = studentInfo.name;
                payloadObj[`stu${index+1}_registered_grade`] = studentInfo.stud_grade.map((eachGrade) => eachGrade.grade_name).join(';');
            }
        }
        return payloadObj;
    }

    createRegistrationInitialPayload(accountInfo: any) {
        let payloadObj = {
            account_id: accountInfo.id,
            parent_name: null,
            number_of_students: 0,
            email: accountInfo.email,
            phone: accountInfo.country_code + accountInfo.phone_number,
            country: accountInfo.country_name,
            actionFrom: 'SIGNUP'
        };
        return payloadObj;
    }

    createPhoneVerifyPayload(accountInfo: any) {
        let payloadObj = {
            account_id: accountInfo.accountId,
            phone: accountInfo.countryCode + accountInfo.phoneNumber,
            country: accountInfo.countryName,
            phone_verified: true,
            actionFrom: 'PHONE_VERIFICATION'
        };
        return payloadObj;
    }

    createFirstUserPayload(studentInfo: any) {
        let payloadObj = {};
        payloadObj[`stu1_birthday`] = studentInfo.date_of_birth;
        payloadObj[`stu1_gender`] = studentInfo.gender;
        payloadObj[`stu1_id`] = studentInfo.id;
        payloadObj[`stu1_name`] = studentInfo.name;
        payloadObj[`stu1_registered_grade`] = studentInfo.stud_grade.map((eachGrade) => eachGrade.grade_name).join(';');
        payloadObj[`actionFrom`] = 'ADD_STUDENT';
        return payloadObj;
    }

    async addIdentity(account_id: any, accountDetails: any) {
        await this.analytics.identify(account_id, accountDetails);
    }

    async addTrack(eventName: string, eventDetails: any) {
        await this.analytics.track(eventName, eventDetails);
    }

    async reset() {
        await this.analytics.reset();
    }

}
