<a class="c-modal-close" href="javascript:void(0);" (click)="dialogRef.close()">&nbsp;</a>
<div class="solution-data">
  <ng-scrollbar>
    <div class="inner-c">
      <div class="q-help-box parent delay-1s animated fadeIn">
        <div class="help-steps">
          <span class="step-no">
            <span class="no">SOLUTION</span>
          </span>
          <div class="text-qa">
            <div class="desC">
              <div class="bx" [appMath]="solution | placeholder"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-scrollbar>
</div>