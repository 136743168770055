import { Component, ContentChild, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { generateUniqueId } from '@studentapp_core/common/reuse-functions';
import { FileUploadingService, fileTypeMappingEnum } from '@studentapp_core/services/file-uploading.service';
import { environment } from '@studentapp_env/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-picture-uploader',
  templateUrl: './picture-uploader.component.html',
  styleUrls: ['./picture-uploader.component.scss']
})
export class PictureUploaderComponent implements OnInit, OnDestroy {

  @ContentChild('centerContentSectionTemplate', { static: true }) centerContentSectionTemplate?: TemplateRef<any>;
  
  @Input() panelClass = '';
  @Input() uploadedFilePath = '';
  @Input() useAbsolutePath = false;
  @Input() imageWidth ?= 300;
  @Input() imageCompressionRate ?= 0.5;
  @Input() imageCompressionRequired ?= true;
  @Input() maxImageSizeInByte ?= 2000000;
  @Input() maxVideoSizeInMb ?= 500;
  @Input() maxFileSizeInMb ?= 500;
  @Input() headingLabel ?= 'Upload image';
  @Input() fieldTitle ?= '';
  @Input() fieldSubTitle ?= '';
  @Input() fileType?: 'IMAGE' | 'VIDEO' | 'ANY_FILE' = 'IMAGE';
  @Input() uploadType?: 'image' | 'video' | 'chat_attachment_files' = 'image';
  @Input() panelClassForFilePreview = '';
  @Input() isDeleteAllowed = true;
  @Input() isInstantDeleteAllowed = true;
  @Input() isPreviewRequired ?= true;
  @Input() uploadingMessageText = 'Uploading...';
  @Input() allowMultiple = false;

  @Output() imageUploadStarted = new EventEmitter<any>();
  @Output() imageRemoved = new EventEmitter<boolean>();
  @Output() imageUploaded = new EventEmitter<any>();
  @Output() imageUploadedFullInfo = new EventEmitter<any>();
  @Output() videoDuration = new EventEmitter<any>();
  @Output() fileContentEmitter = new EventEmitter<any>();
  @Output() fileToBeRemoveLaterEmitter = new EventEmitter<any>();

  env = environment;
  imagePath = this.env.cdn_urls.image;
  videoPath = this.env.cdn_urls.video;

  fileModel = '';
  currentStatus = '';
  fileName = '';
  allowedAcceptedFileType = '';
  percentDone = 0;
  fileTypeMappingObj = fileTypeMappingEnum;
  subscriptions: Subscription[] = [];

  constructor(
    private fileUploadingService: FileUploadingService,
  ) {

  }

  checkImageFileExtension(file) {
    let isOk = true;
    if (!file.name.match(/.(jpg|jpeg|png|gif)$/i)) {
      this.fileModel = '';
      isOk = false;
      alert('Only jpg,jpeg,png,gif are allowed');
    }
    return isOk;
  }

  checkVideoFileExtension(file) {
    let isOk = true;
    if (!file.name.match(/.(mp4)$/i)) {
      this.fileModel = '';
      isOk = false;
      alert('Only MP4 are allowed');
    }
    return isOk;
  }

  checkImageFileSize(file) {
    let isOk = true;
    if (file.size > this.maxImageSizeInByte) {
      this.fileModel = '';
      isOk = false;
      alert('Image must be less than ' + (this.maxImageSizeInByte / 1000).toFixed(2) + ' KB');
    }
    return isOk;
  }

  checkVideoFileSize(file) {
    let isOk = true;
    const size = file.size / (1024 * 1024);
    if (size > this.maxVideoSizeInMb) {
      this.fileModel = '';
      isOk = false;
      alert('Video must be less than ' + (this.maxVideoSizeInMb) + ' MB');
    }
    return isOk;
  }

  checkOneFileSize(file) {
    let isOk = true;
    const size = file.size / (1024 * 1024);
    if (size > this.maxFileSizeInMb) {
      this.fileModel = '';
      isOk = false;
      alert('File must be less than ' + (this.maxFileSizeInMb) + ' MB');
    }
    return isOk;
  }

  checkAnyFileExtension(file) {
    let isOk = false;
    if (this.fileType === 'IMAGE') {
      isOk = this.checkImageFileExtension(file);
    }
    else if (this.fileType === 'VIDEO') {
      isOk = this.checkVideoFileExtension(file);
    }
    else if (this.fileType === 'ANY_FILE') {
      isOk = true;
    }
    return isOk;
  }

  checkAnyFileSize(file) {
    let isOk = false;
    if (this.fileType === 'IMAGE') {
      isOk = this.checkImageFileSize(file);
    }
    else if (this.fileType === 'VIDEO') {
      isOk = this.checkVideoFileSize(file);
    }
    else if (this.fileType === 'ANY_FILE') {
      isOk = this.checkOneFileSize(file);
    }
    return isOk;
  }

  async removeImage(fileUrl) {
    try {
      if (this.useAbsolutePath) {
        let arrayUrl = (fileUrl).toString().split('/');
        fileUrl = arrayUrl[arrayUrl.length - 1];
      }
      let fd = new FormData();
      fd.append('file_key', fileUrl);
      fd.append('upload_type', this.fileTypeMappingObj[this.uploadType]);
      await this.fileUploadingService.doDeleteUploadedFileAsynchronous(fd);
      this.uploadedFilePath = '';
      this.imageRemoved.emit(true);
    }
    catch (error) {
    }
  }

  async addImage(event) {
    for (let i = 0; i < event.target.files.length; i++) {
      if (event.target.files && event.target.files[i]) {
        const fileExtensionMatched = this.checkAnyFileExtension(event.target.files[i]);
        const fileSizeMatched = this.checkAnyFileSize(event.target.files[i]);

        if (fileExtensionMatched && fileSizeMatched) {
          const _file_id = generateUniqueId();
          let fileToUpload = event.target.files[i];
          if (this.imageCompressionRequired) {
            const compressedFile = await this.fileUploadingService.compressImageCanvas(fileToUpload, this.imageWidth, this.imageCompressionRate);
            fileToUpload = compressedFile.file;
          }
          this.currentStatus = 'INPROGRESS';
          this.imageUploadStarted.emit({ file_name: fileToUpload.name, file: fileToUpload, status: this.currentStatus });
          try {
            let fd = new FormData();
            fd.append('file', fileToUpload);
            fd.append('upload_type', this.fileTypeMappingObj[this.uploadType]);
            const fileData: any = await this.fileUploadingService.doUploadFileAsynchronous(fd);
            this.currentStatus = 'COMPLETED';
            if (this.useAbsolutePath) {
              this.uploadedFilePath = `${this.imagePath}${fileData.uploaded_file_name}`;
            }
            else {
              this.uploadedFilePath = fileData.uploaded_file_name;
            }
            this.imageUploaded.emit(fileData.uploaded_file_name);
            fileData.file_id = _file_id;
            this.imageUploadedFullInfo.emit(fileData);
            this.fileModel = '';
          }
          catch (error) {
            this.currentStatus = 'FAILED';
            this.uploadedFilePath = '';
            this.fileModel = '';
            this.imageUploadStarted.emit({ file_name: fileToUpload.name, file: fileToUpload, status: this.currentStatus });
          }
        }
      }
    }
  }

  durationChangeEventHandler(event: any) {
    const durationInSec = event.target.duration;
    let min = Math.round(durationInSec / 60);
    let sec = 0;
    const remainSec = durationInSec - (min * 60);
    if (remainSec > 0) {
      sec = remainSec;
      min+= 1;
    }
    this.videoDuration.emit( { inSeconds: durationInSec, inMinutes: min, general: { min, sec } });
  }

  ngOnInit(): void {
    if (this.fileType === 'IMAGE') {
      this.allowedAcceptedFileType = '.jpeg,.png,.jpg,.gif';
    }
    else if (this.fileType === 'VIDEO') {
      this.allowedAcceptedFileType = '.mp4';
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

}
