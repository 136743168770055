<div id="current-user-badge"></div>
<section class="main_Body_Area sidebar-new">
    <div class="overlay-superbook" id="overlay-superbook" (click)="remove_nav()">

    </div>
    <app-page-display-structure class="bg-gray" [productInfo]="productInfo"></app-page-display-structure>
    <div class="content-wraper">
        <router-outlet></router-outlet>
    </div>
</section>
<ng-container *ngComponentOutlet="liveClasscomponent; ngModuleFactory: liveClassModule"></ng-container>
<router-outlet name="modal"></router-outlet>
