import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageDisplayStructureComponent } from './page-display-structure.component';
import { TopNavbarDefaultModule } from '../top-navbar-default/top-navbar-default.module';
import { LeftSidebarModule } from '@studentapp_pages/layout/left-sidebar/left-sidebar.module';
import { RouterModule } from '@angular/router';
import { BottomNavbarDefaultModule } from '../bottom-navbar-default/bottom-navbar-default.module';

@NgModule({
  declarations: [
    PageDisplayStructureComponent
  ],
  imports: [
    CommonModule,
    TopNavbarDefaultModule,
    LeftSidebarModule,
    BottomNavbarDefaultModule,
  ],
  exports: [
    PageDisplayStructureComponent,
  ]
})
export class PageDisplayStructureModule { }
