<ng-template #defaultCenterContentSectionTemplate>
    <span class="t" *ngIf="headingLabel">{{ headingLabel }}</span>
</ng-template>

<div class="upload-wraper" [ngClass]="panelClass">
    <label class="field-title" *ngIf="fieldTitle">
        {{ fieldTitle }} <small *ngIf="fieldSubTitle" [innerHTML]="fieldSubTitle"></small>
    </label>
    <div class="upload-image-sec" [ngClass]="{'video-uploaded' : uploadedFilePath && fileType === 'VIDEO'}">
        <label class="upload-img">
            <div class="font-style">
                <ng-container [ngTemplateOutlet]="centerContentSectionTemplate ? centerContentSectionTemplate : defaultCenterContentSectionTemplate"></ng-container>
            </div>
            <input type="file" style="cursor: pointer;" [attr.accept]="fileType === 'IMAGE' ? '.jpg, .png' : '.mp4'" [(ngModel)]="fileModel" (change)="addImage($event);" />
        </label>

        <ng-container *ngIf="isPreviewRequired">
            <div class="view-upload-img" *ngIf="uploadedFilePath && fileType === 'IMAGE'">
                <button type="button" class="img-remove-btn" (click)="removeImage(uploadedFilePath)">
                    <em class="material-icons">close</em>
                </button>
                <img [src]="useAbsolutePath === false ? (imagePath + uploadedFilePath) : uploadedFilePath" alt="" />
            </div>
            <div class="view-upload-img" *ngIf="uploadedFilePath && fileType === 'VIDEO'">
                <button type="button" class="img-remove-btn" (click)="removeImage(uploadedFilePath)">
                    <em class="material-icons">close</em>
                </button>
                <video [src]="useAbsolutePath === false ? (videoPath + uploadedFilePath) : uploadedFilePath" controls #videoElementView (loadeddata)="durationChangeEventHandler($event , 'LOAD')"></video>
            </div>
            <app-progress-spinner class="loader-center-page absolute-position h-100 with-bg" *ngIf="currentStatus === 'INPROGRESS'"></app-progress-spinner>
        </ng-container>
    </div>
</div>