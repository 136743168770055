import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from '../../../../app/core/services/common.service';

@Component({
  selector: 'app-test-module-alert-dialog',
  templateUrl: './test-module-alert-dialog.component.html',
  styleUrls: ['./test-module-alert-dialog.component.scss']
})
export class TestModuleAlertDialogComponent implements OnInit {

  givenTime: string = null;
  timeLeft: string = null;
  timeTaken: string = null;

  constructor(
    public commonService: CommonService,
    public dialogRef: MatDialogRef<TestModuleAlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any) {
    this.givenTime = dialogData.givenTime !== null ? this.commonService.calculation(dialogData.givenTime).format : null;
    this.timeLeft = dialogData.timeLeft !== null ? this.commonService.calculation(dialogData.timeLeft).format : null;
    this.timeTaken = dialogData.timeTaken !== null ? this.commonService.calculation(dialogData.timeTaken).format : null;
  }

  /* get time in second */
  getTime(second) {
    this.timeLeft = this.commonService.calculation(second).format;
  }


  ngOnInit() {
  }

}
