import { Component, Inject, OnInit, Pipe, PipeTransform } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '../../../core/services/APIService/auth.service';

@Pipe({ name: 'safehtml' })
export class SafelPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

@Pipe({
  name: 'placeholder'
})

export class PlaceholderPipe implements PipeTransform {

  constructor(private authService: AuthService) { }

  transform(value: any): any {

    let currentStudentData = null;

    if (this.authService.getCurrentStudentData())
      currentStudentData = this.authService.getCurrentStudentData();
    else currentStudentData = null;

    if (!value || !currentStudentData) return;

    const result = value.replace(new RegExp('<span class="placeholder" name="Student Name">{Student Name}</span>', 'g'),
      `<span style="font-weight: 600">${currentStudentData.nick_name ? currentStudentData.nick_name : currentStudentData.name}</span>`)
      .replace(new RegExp('<span class="placeholder" name="Student Name">{Student Name}</span>', 'g'), `<span style="font-weight: 600">${currentStudentData.nick_name ? currentStudentData.nick_name : currentStudentData.name}</span>`);

    return result;

  }
}



@Component({
  selector: 'app-solution-dialog',
  templateUrl: './solution-dialog.component.html',
  styleUrls: ['./solution-dialog.component.scss']
})
export class SolutionDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SolutionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public solution: any) { }

  ngOnInit() {
  }

}
