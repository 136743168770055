<a href="javascript:void(0);" class="pop-close" (click)="cancel()">
  <img src="assets/images/svg/close.svg" alt="">
</a>
<mat-dialog-content>
  <Form [formGroup]="Form" novalidate (submit)="submit()">
    <div class="doubts-popup modern-material-form">
      <div class="doubts-popup-c">
        <div class="head">
          <h2> Support</h2>
        </div>
        <div class="body">
          <div class="form-group">
            <label>Enter Description Here:</label>
            <textarea  formControlName="DESCRIPTION" class="form-control" placeholder="Write something here....." spellcheck="false"></textarea>
          </div>
          <div class="form-group">
            <app-picture-uploader 
              [fieldTitle]="'Upload image'"
              [panelClass]="'rounded'"
              [fieldSubTitle]="''" 
              [headingLabel]="'Upload image'"
              [uploadedFilePath]="''"
              [imageCompressionRequired]="false"
              (imageRemoved)="removeUploadedImage($event)"
              (imageUploadedFullInfo)="newImageAdded($event)"
            >
              <ng-template #centerContentSectionTemplate>
                <span class="t">Drag &amp; Drop</span>
                <span class="sm-t">To Upload Image</span>
                <span class="btn btn-primary btn-sm btn-rounded">Or, Click to Browse</span>
              </ng-template>
            </app-picture-uploader>
            <!-- <label>Upload image:</label>
            <label class="custom-uploader" *ngIf="!key.value">
              <input type="file" (change)="addImage($event)"/>
              <span class="upload-area">
                <img src="assets/images/svg/upload.svg" alt=""/>
                <span class="t">Drag &amp; Drop</span>
                <span class="sm-t">To Upload Image</span>
                <span class="btn btn-primary btn-sm btn-rounded">Or, Click to Browse</span>
              </span>
            </label>
            <div class="up-image-show" *ngIf="key.value" (click)="removeImage()">
              <a class="cls" href="javascript:void(0);">
                <img src="assets/images/svg/close.svg" alt=""/>
              </a>
              <img alt="image" [src]="url.value">
            </div> -->
          </div>
          <div class="d-block text-center pt-3">
            <button type="submit" class="btn btn-md set-button dark-blue shadow-none min-w-160 radius-10">Submit</button>
            <!-- <span class="msg-info">One Ask Credits will be Deducted</span> -->
          </div>
        </div>
      </div>
    </div>
  </Form>
</mat-dialog-content>