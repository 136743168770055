import { Component, ViewChild, ComponentFactoryResolver, ApplicationRef, Injector, AfterViewInit, OnDestroy, Input, Inject } from '@angular/core';
import { CdkPortal, DomPortalOutlet } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html'
})

export class PortalComponent implements AfterViewInit, OnDestroy {

  @Input('portalId') id: string;

  @ViewChild(CdkPortal, { static: true }) private portal: CdkPortal;
  host: DomPortalOutlet;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private cfr: ComponentFactoryResolver,
    private ar: ApplicationRef,
    private injector: Injector
  ) { }


  ngAfterViewInit(): void {
    console.log('portalId', this.id);
    // setTimeout(() => {
    this.host = new DomPortalOutlet(
      // this.document.querySelector(`#right-section-footer`),
      this.document.querySelector(`#${this.id}`),
      this.cfr,
      this.ar,
      this.injector
    );
        // console.log('portalHost', this.host, this.portal);
    this.host.attach(this.portal);
    // }, 5000);
  }

  ngOnDestroy(): void {
    if (!this.host) return;
    this.host.detach();
  }

}
