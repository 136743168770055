import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class ValidationService {
    headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    private BASE_URL = environment.base_url;

    constructor(private http: HttpClient) { }

    checkParentEmail(parent_email: string, parentId: number) {
        let $OBJ = {
            email: parent_email,
            user_id: parentId
        }
        return this.http.post(`${this.BASE_URL}/register/check/email/`, $OBJ, { headers: this.headers });
    }
    checkParentPhone(country_code: string, phone_number: string, parentId: number) {
        let $OBJ = {
            country_code: country_code,
            phone_number: phone_number,
            user_id: parentId
        }
        return this.http.post(`${this.BASE_URL}/register/check-user-phone-number/`, $OBJ, { headers: this.headers });
    }

}
