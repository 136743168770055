import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'leftsectionfilter',
  pure: false
// 
})
export class LeftsectionfilterPipe implements PipeTransform {

 
  transform(content: Array<any>, args?: string): any {
    // if(!value) return ;
    // console.log('valueee',content)
    // console.log('****step-section',content['step_section'])
    // console.log('****step-section**length',content['step_section'].length)

    // console.log('args',args)


    if(content['step_section'].length > 0) {
      const totalSection = content['step_section'].length;
      const lastSectionIndex  = totalSection - 1;
      const lastSection = content['step_section'][lastSectionIndex];

      // console.log('totalSection',totalSection)
      // console.log('lastSectionIndex',lastSectionIndex)
      // console.log('lastSection',lastSection)


      if (!isNaN(lastSection.allsections[0].leftSectionIndex)) {
        // console.log('matchleftsectionIndex >>>', lastSection.allsections[0].leftSectionIndex);
        return lastSection.allsections[0].leftSectionIndex;
      }
      return 'all';
    }
    return 'all';
    

  }

}
