import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'doer-do',
		pathMatch: 'full'
	},
	{
		path: 'doer-do',
		data: {
			branchLevel: 'doerDo',
		},
		loadChildren: () => import('./content/_product_management/doer-do-product-layout/doer-do-product-layout.module').then(m => m.DoerDoProductLayoutModule),
	},
	{
		path: 'doer-code',
		data: {
			branchLevel: 'doerCode',
		},
		loadChildren: () => import('./content/_product_management/doer-code-product-layout/doer-code-product-layout.module').then(m => m.DoerCodeProductLayoutModule),
	},
	{
		path: 'doer-talk',
		data: {
			branchLevel: 'doerTalk',
		},
		loadChildren: () => import('./content/_product_management/doer-talk-product-layout/doer-talk-product-layout.module').then(m => m.DoerTalkProductLayoutModule),
	},
	{
		path: 'doer-math',
		data: {
			branchLevel: 'doerMath',
		},
		loadChildren: () => import('./content/_product_management/doer-math-product-layout/doer-math-product-layout.module').then(m => m.DoerMathProductLayoutModule),
	},
	{
		path: 'doer-spark',
		data: {
			branchLevel: 'doerSpark',
		},
		loadChildren: () => import('./content/_product_management/doer-spark-product-layout/doer-spark-product-layout.module').then(m => m.DoerSparkProductLayoutModule),
	},
	{
		path: 'student',
		loadChildren: () => import('./content/authentication/student-auth/student-auth.module').then(m => m.StudentAuthModule),
		outlet: 'modal',
		// canActivate: [PublicGuard]
	},
	{
		path: 'logic-lesson/:id',
		loadChildren: () => import('./content/subject/display-content/right-section/right-section-body/logical-lesson/logical-lesson/logical-lesson.module').then(m => m.LogicalLessonModule),
		// canActivate: [AuthGuard]
	},
	{
		path: 'parent',
		loadChildren: () => import('./content/authentication/parent-auth/parent-auth.module').then(m => m.ParentAuthModule),
		// canActivate: [PublicGuard]
	},
	{
		path: 'management',
		loadChildren: () => import('./content/account-management/account-management.module').then(m => m.AccountManagementModule),
		outlet: 'modal'
	},
	// {
	// 	path: 'assessments',
	// 	loadChildren: () => import('./content/assessment/assessment.module').then(m => m.AssessmentModule),
	// },
	{
		path: 'feature-support',
		loadChildren: () => import('./content/feature-support/feature-support.module').then(m => m.FeatureSupportModule),
	},
	{
		path: 'catalogue',  /// DESIGN ONLY
		loadChildren: () => import('./content/_designer_design/catalogue-list/catalogue-list.module').then(m => m.CatalogueListModule),
	},
	{
		path: 'video-class-design', /// DESIGN ONLY
		loadChildren: () => import('./content/_designer_design/design-video-class/design-video-class.module').then(m => m.DesignVideoClassModule),
	},
	{
		path: 'course-path-design', /// DESIGN ONLY
		loadChildren: () => import('./content/_designer_design/design-course-path/design-course-path.module').then(m => m.DesignCoursePathModule),
	},
	{
		path: 'doergrade-design', /// DESIGN ONLY
		loadChildren: () => import('./content/_designer_design/design-doergrade/design-doergrade.module').then(m => m.DesignDoergradeModule),
	},
	{
		path: 'course-detail-design', /// DESIGN ONLY
		loadChildren: () => import('./content/_designer_design/design-course-detail/design-course-detail.module').then(m => m.DesignCourseDetailModule),
	},
	{
		path: 'new-course-detail-design', /// DESIGN ONLY
		loadChildren: () => import('./content/_designer_design/design-course-info-view/design-course-info-view.module').then(m => m.DesignCourseInfoViewModule),
	},
	{
		path: '**',
		redirectTo: 'doer-do',
		pathMatch: 'full'
	}
];

const routerOptions: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64],
	onSameUrlNavigation: 'reload',
	useHash: true,
	relativeLinkResolution: 'legacy',
	// enableTracing: true
};
@NgModule({
	imports: [CommonModule, RouterModule.forRoot(routes, routerOptions)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
