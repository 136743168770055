import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../../../core/services/APIService/auth.service';
import { environment } from '@studentapp_env/environment';


@Component({
  selector: 'app-feature-support-dialog',
  templateUrl: './feature-support-dialog.component.html',
  styleUrls: ['./feature-support-dialog.component.scss']
})
export class FeatureSupportDialogComponent implements OnInit {

  Form: FormGroup;
  uploadStatus: any[] = ['success', 'in-progress', 'failed'];

  env = environment;
  imagePath = this.env.cdn_urls.image;

  constructor(
    public dialogRef: MatDialogRef<FeatureSupportDialogComponent>,
    private authService: AuthService,
    private snackBar: MatSnackBar,
  ) {
    this.dialogRef.disableClose = true;
    this.Form = new FormGroup({
      DESCRIPTION: new FormControl('', [Validators.required, Validators.maxLength(500)]),
      IMAGE: new FormGroup({
        KEY: new FormControl(null),
        NAME: new FormControl(null),
        URL: new FormControl(null),
        UPLOAD_STATUS: new FormControl(null)
      }),
    });
  }


  get description() {
    return this.Form.get('DESCRIPTION');
  }

  get key() {
    return this.Form.get('IMAGE').get('KEY');
  }

  get name() {
    return this.Form.get('IMAGE').get('NAME');
  }

  get url() {
    return this.Form.get('IMAGE').get('URL');
  }

  get upload_status() {
    return this.Form.get('IMAGE').get('UPLOAD_STATUS');
  }

  newImageAdded(event: any) {
    this.Form.get('IMAGE').patchValue({
      KEY: event.uploaded_file_name,
      NAME: event.file_name,
      URL: `${this.imagePath}${event.uploaded_file_name}`,
      UPLOAD_STATUS: 'success'
    });
  }

  removeUploadedImage(event: any) {
    this.Form.get('IMAGE').patchValue({
      KEY: null,
      NAME: null,
      URL: null,
      UPLOAD_STATUS: null,
    });
  }

  /* once the modal is closed */
  async cancel() {
    // if (this.key.value) await this.removeImage();
    this.dialogRef.close();
  }

  /* check for form elements validations */
  checkValidation() {
    if (this.description.invalid) {
      if (this.description.errors && this.description.errors.required)
        this.snackBar.open('please write a description', null, { duration: 3000, verticalPosition: 'top', horizontalPosition: 'end' });
      if (this.description.errors && this.description.errors.maxlength)
        this.snackBar.open(`description exceeded ${this.description.errors.maxlength.requiredLength} characters`, null, { duration: 3000, verticalPosition: 'top', horizontalPosition: 'end' });
    }
  }



  /* collect all the information about doubts */
  submit() {
    if (this.Form.invalid) { this.checkValidation(); return; }
    const customer_id = this.authService.getCurrentUser() ?
      this.authService.getCurrentUser().user.id :
      null;
    const description = this.description.value;
    if (!customer_id) return;
    console.log(this.key.value);
    const OBJ = {
      customer_id: customer_id,
      description: description,
      image_url: this.key.value ? [
        {
          key: this.key.value,
          name: this.name.value,
          url: this.url.value,
          upload_status: this.upload_status.value
        }
      ] : []
    }

    this.authService.addNewTicket(OBJ).subscribe((response: any) => {
      const OBJ = {
        id: response.id,
        status: response.status,
        category: response.category,
        ticket_created_at: new Date(),
        description: this.description.value,
        unread_conversation: 0
      }
      this.dialogRef.close(OBJ);
    });
  }


  ngOnInit() {
  }

}

