<a href="javascript:void(0);" class="pop-close" (click)="cancel()">
  <img src="assets/images/svg/close.svg" alt="">
</a>
<!-- <div mat-dialog-title class="m-0" style="min-height: 10px; line-height: 10px;">&nbsp;</div> -->
<mat-dialog-content *ngIf="issuesIist.length > 0">
  <div class="report-container pt-0 modern-material-form">
    <form [formGroup]="reportForm" name="form" (ngSubmit)="saveReport()" #f="ngForm" novalidate>
      <h2>Report a problem</h2>
      <div class="report-options">
        <label class="rdo-list" *ngFor="let list of issuesIist;let i = index" >
          <input type="radio" formControlName="REPORT_ID" required [value]="list.id"/>
          <span class="rdo-c">
            <span class="ico"></span>
            {{list.issue_name}}
          </span>
        </label>
      </div>
      <!-- <div class="report-btns">
        <a href="javascript:void(0);" class="text-ico-btn">
          SKIP <img src="assets/images/svg/skip.svg" alt="">
        </a>
      </div> -->
      <div class="form-box p-0 mt-3">
        <div class="form-group">
          <label>More Info</label>
          <textarea class="form-control" formControlName="DESCRIPTION" name="description" placeholder="Tell us more about what's happening" autocomplete="off" maxlength="200" (keydown)="descriptionArea($event)"></textarea>
          <mat-error class="float-right" *ngIf="description.value.length > 50">{{200 - description.value.length}} characters left</mat-error>
        </div>

        <div class="form-group">
          <app-picture-uploader 
            [fieldTitle]="'Upload image'"
            [panelClass]="'rounded'"
            [fieldSubTitle]="''" 
            [headingLabel]="''"
            [uploadedFilePath]="''"
            [imageCompressionRequired]="false"
            [useAbsolutePath]="true"
            (imageRemoved)="removeUploadedImage($event)"
            (imageUploadedFullInfo)="newImageAdded($event)"
          >
            <ng-template #centerContentSectionTemplate>
              <span class="t">Drag &amp; Drop</span>
              <span class="sm-t">To Upload Image</span>
              <span class="btn btn-primary btn-sm btn-rounded">Or, Click to Browse</span>
            </ng-template>
          </app-picture-uploader>
          <!-- <label>Upload image:</label>

          <div class="report-image-show"*ngIf="image_url.value">
            <a href="javascript:void(0);" class="cls" (click)="removeImage()">
              <img src="assets/images/svg/close.svg" alt="">
            </a>
            <img [src]="image_url.value" alt="image">
          </div>

          <label class="custom-uploader" *ngIf="!(image_url.value)">
            <input type="file" (change)="addImage($event);">
            <span class="upload-area">
              <img src="assets/images/svg/upload.svg" alt="">
              <span class="t">Drag &amp; Drop</span>
              <span class="sm-t">To Upload Image</span>
              <span class="btn btn-primary btn-sm btn-rounded">Or, Click to Browse</span>
            </span>
          </label> -->

          <!-- <div class="upload-image">
            <label class="math-back-btn up-btn">
              <input type="file" (change)="addImage($event);"/>
              <input class="form-control" formControlName="IMAGE_URL" required name="image" autocomplete="off" [hidden]="true" />
              <i class="fas fa-plus"></i>&nbsp;&nbsp;Browse
            </label>
            <span class="nm" *ngIf="image_name.value">{{image_name.value}} <i class="fas fa-times" (click)="removeImage()" *ngIf="image_url.value"></i></span>
          </div> -->

        </div>
      </div>
      <div class="buttonset text-center mt-5">
        <!-- <button class="btn btn-md set-button btn-secondary mr-2 min-w-80" (click)="cancel()">Cancel</button> -->
        <button type="submit" class="btn btn-md set-button min-w-160" [disabled]="reportForm.invalid">Save</button>
      </div>
    </form>
  </div>
</mat-dialog-content>