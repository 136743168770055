import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../../../core/services/APIService/auth.service';
import { InformationService } from '../../../core/services/information.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '@studentapp_env/environment';

@Component({
  selector: 'app-report-dialog',
  templateUrl: './report-dialog.component.html',
  styleUrls: ['./report-dialog.component.scss']
})
export class ReportDialogComponent implements OnInit {

  issuesIist: any[] = [];

  reportForm: FormGroup;

  env = environment;
  imagePath = this.env.cdn_urls.image;

  constructor(
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private informationService: InformationService,
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public component_info: any) {
    dialogRef.disableClose = true;
    console.log({ component_info });

    this.reportForm = new FormGroup({
      REPORT_ID: new FormControl(null, [Validators.required]),
      DESCRIPTION: new FormControl('', [Validators.maxLength(200)]),
      IMAGE_NAME: new FormControl('', []),
      IMAGE_URL: new FormControl('', [])
    });
  }

  get report_id() {
    return this.reportForm.get('REPORT_ID');
  }
  get description() {
    return this.reportForm.get('DESCRIPTION');
  }
  get image_name() {
    return this.reportForm.get('IMAGE_NAME');
  }
  get image_url() {
    return this.reportForm.get('IMAGE_URL');
  }


  /* save the report to DB */
  saveReport() {

    if (this.reportForm.invalid) return;

    let issues_capture_obj = {
      super_book: this.component_info.superbook_id,
      student: this.authService.getCurrentStudentData().id,
      issue: this.report_id.value,
      issue_description: this.description.value,
      image_url: this.image_url.value,
      component_id: this.component_info.component_id,
      component_type: this.component_info.component_type,
      status: 0,
      device_info: this.informationService.getDevice(),
      user_browser: this.informationService.getBrowser(),
      operating_system: this.informationService.getOSDetails().version ? this.informationService.getOSDetails().version : this.informationService.getOSDetails().type,
      screen_size: `${this.informationService.getResolution().width} x ${this.informationService.getResolution().height}`
    }

    console.log({ issues_capture_obj });

    this.authService.uploadReportInfo(issues_capture_obj).subscribe((response) => {
      if (response) this.snackBar.open('Thank you for the feedback', null, { duration: 2000, verticalPosition: 'top', horizontalPosition: 'end' });
      this.dialogRef.close();
    },
      error => {
        console.error(error);
        this.snackBar.open('Failed! please retry', null, { duration: 2000, verticalPosition: 'top', horizontalPosition: 'end' });
      });
  }

  newImageAdded(event: any) {
    this.reportForm.patchValue({
      IMAGE_NAME: event.file_name,
      IMAGE_URL: `${this.imagePath}${event.uploaded_file_name}`
    });
  }

  removeUploadedImage(event: any) {
    this.reportForm.patchValue({
      IMAGE_NAME: '',
      IMAGE_URL: ''
    });
  }

  /* once the modal is closed */
  async cancel() {
    // if (this.image_url.value) await this.removeImage();
    this.dialogRef.close();
  }


  /* get the issues list */
  getIssuesList() {
    let OBJ = {
      component_type: this.component_info.component_type
    }
    this.authService.getIssues(OBJ).subscribe((issues: any) => {
      console.log(issues);
      this.issuesIist = issues.length > 0 ? issues : [];
    },
      error => console.error(error));
  }

  descriptionArea(event){
    console.log(event);
      setTimeout(function(){
      event.srcElement.style.height = "";
      let latestHeight = event.srcElement.scrollHeight + 2;
      event.srcElement.style.height = +latestHeight+'px';
    },0)
  }

  ngOnInit() {
    this.getIssuesList()
  }

}
