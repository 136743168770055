import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { CommonService } from "../services/common.service";
import { catchError, tap, finalize } from "rxjs/operators";
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from "src/app/content/dialog-box/error-dialog/error-dialog.component";
import { AuthService } from "../services/APIService/auth.service";
import { ActivatedRoute, Router } from "@angular/router";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    dialogRef = null;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public dialog: MatDialog,
        private authService: AuthService,
        private commonService: CommonService) {
    }

    errorHandler(error) {

        // console.log('sds',this.dialogRef);
        // if (this.dialogRef !== null) return error.error;
        // this.dialogRef.afterClosed().pipe(
        //     finalize(() => this.dialogRef = null)
        // );

        switch (error.status) {
            case 401: {
                console.error('Session Timeout!');
                // this.dialogRef = this.dialog.open(ErrorDialogComponent, {
                //     width: '500px',
                //     data: error
                // });
                return error;
            }
            case 500: {
                console.error('Server Not Responding!');
                this.dialogRef = this.dialog.open(ErrorDialogComponent, {
                    width: '500px',
                    data: error
                });
                return error;
            }
            case 404: {
                console.error('Requested Resource Not Found!');
                this.dialogRef = this.dialog.open(ErrorDialogComponent, {
                    width: '500px',
                    data: error
                });
                return error;
            }
            case 405: {
                console.error('Request suspended!');
                // location.reload();
                return error;
            }
            case 412:
                return error;
                
            case 0: {
                console.error('CORS!');
                // location.reload();
                return error;
            }
            default: return error.error;
        }
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const currentUser = this.authService.getCurrentUser();
        const currentStudent = this.authService.getCurrentStudentData();

        if (currentUser) {
            // if (currentStudent) {
            //     req = req.clone({
            //         setHeaders: {
            //             // "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization,Userpubickey",
            //             Authorization: `Token ${currentUser.token}`,
            //             // Userpubickey: currentStudent.id,
            //             'X-Requested-With': 'XMLHttpRequest',
            //         }
            //     });
            // } else {
            req = req.clone({
                setHeaders: {
                    Authorization: `Token ${currentUser.token}`,
                    'X-Requested-With': 'XMLHttpRequest'
                }
            });
            // }
        } else {
            // req = req.clone({
            //     setHeaders: {
            //         'X-Requested-With': 'XMLHttpRequest'
            //     }
            // });
        }

        return     next.handle(req).pipe(
            tap((val) => {
                console.log('authorization process');
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    this.authService.removeCurrentUserData();
                    this.authService.removeCurrentStudentData();
                    this.authService.removeUserAuthenticated();
                    setTimeout(() => {
                        this.router.navigate(['.', { outlets: { modal: 'student/login' } }], { queryParamsHandling: 'preserve', relativeTo: this.activatedRoute })
                        // this.router.navigate(['student/login']);
                        // location.href = '/student/login';
                    }, 3000);
                }
                return throwError(this.errorHandler(error));
            })
        );
    }
}