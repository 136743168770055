
  <!-- <div class="product-navcontainer"> -->
    <div class="mainNavContainer">
      <div class="sidemenu-logo" style="display: none;">
        <a href="javascript:void(0);">
          <img src="./assets/images/icons/doerdo-logo-left-panel.png" alt="">
        </a>
      </div>
      <div class="mainNavScrollArea">
        <ng-scrollbar>
          <div class="sideMenu_Area out">
            <div class="logoBox d-none">
              <div class="menu_toggle">
                  <i class="fa fa-times " aria-hidden="true"></i>
              </div>          
            </div>

            <ul class="sideMenu">
              <li class="n-nav" [class.active]="checkClassExists(elem1, 'active') || checkClassExists(elem2,'active') || checkClassExists(elem3, 'active') || checkClassExists(elem5, 'active')">
                <a [routerLink]="['/doer-do/learn']">
                  <img class="menuImg no-inv widthimage22" src="../../../../assets/images/icons/doerdo-logo-icon.png">
                  <span class="txt">DoerScore</span>
                </a>
              </li>
              <li class="n-nav" [class.active]="checkClassExists(elem21, 'active') || checkClassExists(elem22, 'active') || checkClassExists(elem24,'active')">
                <a href="javascript:void(0)" [routerLink]="['/doer-code/my-courses']" (click)="resetStudentSelection()">
                  <img class="menuImg no-inv widthimage22" src="../../../../assets/images/icons/doercode_logo_icon_121x121.png">
                  <span class="txt">DoerCode</span>
                </a>
              </li>
              <li class="n-nav" [class.active]="checkClassExists(elem41, 'active') || checkClassExists(elem44,'active')">
                <a href="javascript:void(0)" [routerLink]="['/doer-spark/my-courses']" (click)="resetStudentSelection()">
                  <img class="menuImg no-inv widthimage22" src="../../../../assets/images/icons/doerspark_logo_icon_121x121.png">
                  <span class="txt">DoerSpark</span>
                </a>
              </li>
              <li class="n-nav" [class.active]="checkClassExists(elem51, 'active') || checkClassExists(elem54,'active')">
                <a href="javascript:void(0)" [routerLink]="['/doer-math/my-courses']" (click)="resetStudentSelection()">
                  <img class="menuImg no-inv widthimage22" src="../../../../assets/images/icons/doermath_logo_icon_green_121x121.png">
                  <span class="txt">DoerMath</span>
                </a>
              </li>
              <li class="n-nav" [class.active]="checkClassExists(elem31, 'active') || checkClassExists(elem34,'active')">
                <a href="javascript:void(0)" [routerLink]="['/doer-talk/my-courses']" (click)="resetStudentSelection()">
                  <img class="menuImg no-inv widthimage22" src="../../../../assets/images/icons/doertalk_logo_icon_121x121.png">
                  <span class="txt">DoerTalk</span>
                </a>
              </li>

              <li (click)="subscription()" class="foot">
                <a href="javascript:void(0)">
                  <img class="menuImg " src="../../../../assets/images/icons/subscribe-purple.svg">
                  <span class="txt">Subscription</span>
                </a>
              </li>
              <li [routerLink]="['/feature-support']" routerLinkActive="active">
                <a href="javascript:void(0);">
                  <img class="menuImg " src="../../../../assets/images/icons/assessment-support-purple.svg">
                  <span class="txt">Support</span>
                  <span class="notifyNo"  *ngIf="(dataService.UnreadConvCount$ | async) as convCount" [hidden]="convCount === 0">{{convCount > 9 ? '9+' : convCount > 0 ? convCount : ''}}</span>
                </a>
              </li>
              <li [routerLink]="['', { outlets: { modal: 'management/account/login' }}]" [queryParamsHandling]="''" [state]="resolverSkipObjectData">
                <a href="javascript:void(0)">
                  <img class="menuImg " src="../../../../assets/images/icons/account-icon-purple.svg">
                  <span class="txt">Settings</span>
                </a>
              </li>
              <li class="logout-li-button" (click)="logout()">
                <a href="javascript:void(0)" >
                  <img class="menuImg " src="../../../../assets/images/icons/logout-icon-purple.svg">
                  <span class="txt">Logout</span>
                </a>
              </li>
            </ul>
          </div>
        </ng-scrollbar>
      </div>
    </div>

    <div class="product-subnav" id="left-menu-sidebar-wrapper">
      
      <div class="app-nav-container" id="left-main-first-menu-bar">
        <div class="app-nav-breadcrumb-container" id="left-main-menu-head-breadcrumb-section"></div>
        <div class="app-nav-area">
          <ng-scrollbar>
            <!-- DOERDO PRODUCT MENU START -->
            <ul class="app-main-nav" [hidden]="productInfo !== 'doerDo'">
              <!-- NAV 2 START -->
              <!-- <li class="drop-menu-item-set sm-view" #elem1 [class.active]="(rla11.isActive || rla12.isActive || rla13.isActive || rla14.isActive || rla15.isActive)" [class.show-menu]="(checkClassExists(elem1, 'show-menu') || (rla11.isActive || rla12.isActive || rla13.isActive || rla14.isActive || rla15.isActive))">
                <button class="nav-a" [matMenuTriggerFor]="navmenu2" >
                  <img src="./assets/images/svg/hierarchy-icon.svg" alt=""> 
                  Learn
                  <span class="drop-arrow"></span>
                </button>
                <mat-menu #navmenu2="matMenu" class="nav-mat-menu">
                  <ul class="app-sub-nav">
                    <li [routerLinkActive]="'active'" #rla11="routerLinkActive">
                      <a mat-menu-item class="nav-a" [routerLink]="['/learn/home']" [queryParamsHandling]="'preserve'" >Learn Home</a>
                    </li>
                    <li [routerLinkActive]="'active'" #rla12="routerLinkActive">
                      <a mat-menu-item class="nav-a" [routerLink]="['/learn/learning-paths']" [queryParamsHandling]="'preserve'">Learning Path</a>
                    </li>
                    <li [routerLinkActive]="'active'" #rla13="routerLinkActive">
                      <a mat-menu-item class="nav-a" [routerLink]="['/learn/courses']" [queryParamsHandling]="'preserve'">Courses</a>
                    </li>
                    <li [routerLinkActive]="'active'" #rla14="routerLinkActive">
                      <a mat-menu-item class="nav-a" [routerLink]="['/work-sheet/listing']" [queryParamsHandling]="'preserve'">Work Sheets</a>
                    </li>
                    <li [routerLinkActive]="'active'" #rla15="routerLinkActive">
                      <a mat-menu-item class="nav-a" [routerLink]="['/mock-exams/listing']" [queryParamsHandling]="'preserve'">Mock Exam</a>
                    </li>
                  </ul>
                </mat-menu>
              </li> -->
      
              <li class="drop-menu-item-set" #elem1 [class.active]="(rla11.isActive || rla12.isActive || rla13.isActive || rla14.isActive || rla15.isActive)" [class.show-menu]="(checkClassExists(elem1, 'show-menu') || (rla11.isActive || rla12.isActive || rla13.isActive || rla14.isActive || rla15.isActive))">
                <a href="javascript:void(0);" [routerLink]="['/doer-do/learn/home']" [queryParamsHandling]="'preserve'" (click)="showDropMenu($event)">
                  <!-- <img src="assets/images/svg/hierarchy-icon.svg" alt="">  -->
                  <img src="./assets/images/icons/book.png" alt="">
                  Learn
                </a>
                <span class="drop-arrow" (click)="toggleDropMenu($event)"></span>
                <ul class="app-sub-nav" (click)="clearLastVisitedMenuSettings()">
                  <li [routerLinkActive]="'active'" #rla11="routerLinkActive">
                    <a [routerLink]="['/doer-do/learn/home']" [queryParamsHandling]="'preserve'" >
                      <img src="./assets/images/icons/learn-home.png" alt=""> 
                      Learn Home
                    </a>
                  </li>
                  <li [routerLinkActive]="'active'" #rla12="routerLinkActive">
                    <a [routerLink]="['/doer-do/learn/learning-paths']" [queryParamsHandling]="'preserve'">
                      <img src="./assets/images/icons/learning-path.png" alt="">
                      Learning Path
                    </a>
                  </li>
                  <li [routerLinkActive]="'active'" #rla13="routerLinkActive">
                    <a [routerLink]="['/doer-do/learn/courses']" [queryParamsHandling]="'preserve'">
                      <img src="./assets/images/icons/courses.png" alt=""> 
                      Courses
                    </a>
                  </li>
                  <li [routerLinkActive]="'active'" #rla14="routerLinkActive">
                    <a [routerLink]="['/doer-do/work-sheet/listing']" [queryParamsHandling]="''">
                      <img src="./assets/images/icons/sheet.png" alt="">
                      Work Sheets
                    </a>
                  </li>
                  <li [routerLinkActive]="'active'" #rla15="routerLinkActive">
                    <a [routerLink]="['/doer-do/mock-exams/listing']" [queryParamsHandling]="''">
                      <img src="./assets/images/icons/mock-exam.png" alt=""> 
                      Mock Exam
                    </a>
                  </li>
                </ul>
              </li>
              <!-- NAV 2 END -->
      
              <!-- NAV 3 START -->
              <!-- <li class="drop-menu-item-set sm-view" #elem2 [class.active]="(rla21.isActive || rla22.isActive || rla23.isActive)" [class.show-menu]="(checkClassExists(elem2, 'show-menu') || (rla21.isActive || rla22.isActive || rla23.isActive))">
                <button class="nav-a" [matMenuTriggerFor]="navmenu3" >
                  <img src="assets/images/svg/course-focus-icon.svg" alt=""> 
                  Live Courses
                  <span class="drop-arrow"></span>
                </button>
                <mat-menu #navmenu3="matMenu" class="nav-mat-menu">
                  <ul class="app-sub-nav">
                    <li [routerLinkActive]="'active'" #rla21="routerLinkActive">
                      <a href="javascript:void(0);" [routerLink]="'/doer-do/live-courses/home'" [queryParamsHandling]="'preserve'">Explore Courses</a>
                    </li>
                    <li [routerLinkActive]="'active'" #rla22="routerLinkActive">
                      <a href="javascript:void(0);" [routerLink]="'/doer-do/my-courses/home'" [queryParamsHandling]="'preserve'">My Courses</a>
                    </li>
                  </ul>
                </mat-menu>
              </li> -->
      
              <li class="drop-menu-item-set" #elem2 [class.active]="(rla21.isActive || rla22.isActive || rla23.isActive)" [class.show-menu]="(checkClassExists(elem2, 'show-menu') || (rla21.isActive || rla22.isActive || rla23.isActive))">
                <a href="javascript:void(0);" [routerLink]="'/doer-do/my-courses'" [queryParamsHandling]="'preserve'" (click)="showDropMenu($event)">
                  <!-- <img src="assets/images/svg/course-focus-icon.svg" alt="">  -->
                  <img src="assets/images/icons/live-course.png" alt="">
                  Live Courses
                </a>
                <span class="drop-arrow" (click)="toggleDropMenu($event)"></span>
                <ul class="app-sub-nav" (click)="clearLastVisitedMenuSettings()">
                  <li [routerLinkActive]="'active'" #rla22="routerLinkActive">
                    <a href="javascript:void(0);" [routerLink]="'/doer-do/my-courses'" [queryParamsHandling]="'preserve'">
                      <img src="assets/images/icons/my-courses.png" alt="">
                      My Courses
                    </a>
                  </li>
                  <li [routerLinkActive]="'active'" #rla21="routerLinkActive">
                    <a href="javascript:void(0);" [routerLink]="'/doer-do/live-courses'" [queryParamsHandling]="'preserve'">
                      <img src="assets/images/icons/all-courses.png" alt=""> 
                      Explore Courses
                    </a>
                  </li>
                </ul>
              </li>
              <li [routerLinkActive]="'active'" #rla23="routerLinkActive" style="display: none">
                <a href="javascript:void(0);" [routerLink]="'/doer-do/live-courses'" [queryParamsHandling]="'preserve'">Default</a>
              </li>
              <!-- NAV 3 END -->

              <!-- NAV 7 START -->   
              <!-- <li class="drop-menu-item-set sm-view" #elem3 [class.active]="(rla31.isActive)" [class.show-menu]="(checkClassExists(elem3, 'show-menu') || (rla31.isActive))">
                <button class="nav-a" [matMenuTriggerFor]="navmenu7" >
                  <img src="assets/images/svg/message-icon.svg" alt=""> 
                  Messages
                  <span class="drop-arrow"></span>
                </button>
                <mat-menu #navmenu7="matMenu" class="nav-mat-menu">
                  <ul class="app-sub-nav">
                    <li [routerLinkActive]="'active'" #rla31="routerLinkActive">
                      <a href="javascript:void(0);" [routerLink]="'/ask-doubt'" [queryParamsHandling]="''">Doubts</a>
                    </li>
                    <li class="disabled">
                      <a href="javascript:void(0);">OneBox</a>
                    </li>
                    <li class="disabled">
                      <a href="javascript:void(0);">Inbox</a>
                    </li>
                    <li class="disabled">
                      <a href="javascript:void(0);">Announcements</a>
                    </li>
                    <li class="disabled">
                      <a href="javascript:void(0);">Chats</a>
                    </li>
                    <li class="disabled">
                      <a href="javascript:void(0);">Threads</a>
                    </li>
                    <li class="disabled">
                      <a href="javascript:void(0);">Issue Reporting</a>
                    </li>
                    <li class="disabled">
                      <a href="javascript:void(0);">Feedback</a>
                    </li>
                  </ul>
                </mat-menu>
              </li> -->
              <li class="drop-menu-item-set" #elem3 [class.active]="(rla31.isActive || rla32.isActive || rla33.isActive)" [class.show-menu]="(checkClassExists(elem3, 'show-menu') || (rla31.isActive || rla32.isActive || rla33.isActive))">
                <a href="javascript:void(0);" [routerLink]="'/doer-do/ask-doubt'" [queryParamsHandling]="'preserve'" (click)="showDropMenu($event)">
                  <!-- <img src="assets/images/svg/message-icon.svg" alt="">  -->
                  <img src="assets/images/icons/message.png" alt="">
                  Messages
                </a>
                <span class="drop-arrow" (click)="toggleDropMenu($event)"></span>
                <ul class="app-sub-nav" (click)="clearLastVisitedMenuSettings()">
                  <li [routerLinkActive]="'active'" #rla31="routerLinkActive">
                    <a href="javascript:void(0);" [routerLink]="'/doer-do/ask-doubt'" [queryParamsHandling]="'preserve'">
                      <img src="assets/images/icons/doubts.png" alt="">
                      Doubts
                    </a>
                  </li>
                  <li class="disabled">
                    <a href="javascript:void(0);">OneBox</a>
                  </li>
                  <li class="disabled">
                    <a href="javascript:void(0);">Inbox</a>
                  </li>
                  <li [routerLinkActive]="'active'" #rla32="routerLinkActive">
                    <a href="javascript:void(0);" [routerLink]="'/doer-do/announcements'" [queryParamsHandling]="'preserve'">Announcements</a>
                  </li>
                  <li [routerLinkActive]="'active'" #rla33="routerLinkActive">
                    <a href="javascript:void(0);" [routerLink]="'/doer-do/channel-communications'" [queryParamsHandling]="'preserve'">Chats</a>
                  </li>
                  <li class="disabled">
                    <a href="javascript:void(0);">Threads</a>
                  </li>
                  <li class="disabled">
                    <a href="javascript:void(0);">Issue Reporting</a>
                  </li>
                  <li class="disabled">
                    <a href="javascript:void(0);">Feedback</a>
                  </li>
                </ul>
              </li>
              <!-- NAV 7 END -->
              
              <!-- NAV 4 START -->
              <!-- <li class="drop-menu-item-set sm-view disabled">
                <button class="nav-a" [matMenuTriggerFor]="navmenu4" >
                  <img src="assets/images/svg/tasks-icon.svg" alt="">
                  Tasks
                  <span class="drop-arrow"></span>
                </button>
                <mat-menu #navmenu4="matMenu" class="nav-mat-menu">
                  <ul class="app-sub-nav">
                    <li class="disabled">
                      <a href="javascript:void(0);">To Do's</a>
                    </li>
                    <li class="disabled">
                      <a href="javascript:void(0);">Class Tests</a>
                    </li>
                    <li class="disabled">
                      <a href="javascript:void(0);">Assignments</a>
                    </li>
                  </ul>
                </mat-menu>
              </li> -->
              <li class="drop-menu-item-set disabled">
                <a href="javascript:void(0);" (click)="toggleDropMenu($event)">
                  <!-- <img src="assets/images/svg/tasks-icon.svg" alt=""> -->
                  Tasks
                </a>
                <span class="drop-arrow"></span>
                <ul class="app-sub-nav">
                  <li class="disabled">
                    <a href="javascript:void(0);">To Do's</a>
                  </li>
                  <li class="disabled">
                    <a href="javascript:void(0);">Class Tests</a>
                  </li>
                  <li class="disabled">
                    <a href="javascript:void(0);">Assignments</a>
                  </li>
                </ul>
              </li>
              <!-- NAV 4 END -->
      
              <!-- NAV 5 START -->        
              <!-- <li class="drop-menu-item-set sm-view disabled">
                <button class="nav-a" [matMenuTriggerFor]="navmenu5" >
                  <img src="assets/images/svg/calendar-icon.svg" alt=""> 
                  Calendar
                  <span class="drop-arrow"></span>
                </button>
                <mat-menu #navmenu5="matMenu" class="nav-mat-menu">
                  <ul class="app-sub-nav">
                    <li>
                      <a href="javascript:void(0);">Folders</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Notes</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Docs</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">MindMaps</a>
                    </li>
                  </ul>
                </mat-menu>
              </li> -->
              <li class="drop-menu-item-set" #elem5 [class.active]="(rla51.isActive)" [class.show-menu]="(checkClassExists(elem5, 'show-menu') || (rla51.isActive))">
                <a href="javascript:void(0);" [routerLink]="'/doer-do/my-calendar'" [queryParamsHandling]="'preserve'" (click)="showDropMenu($event)">
                  <!-- <img src="assets/images/svg/calendar-icon.svg" alt="">  -->
                  Calendar
                </a>
                <span class="drop-arrow" (click)="toggleDropMenu($event)"></span>
                <ul class="app-sub-nav" (click)="clearLastVisitedMenuSettings()">
                  <li [routerLinkActive]="'active'" #rla51="routerLinkActive">
                    <a href="javascript:void(0);" [routerLink]="'/doer-do/my-calendar/home'" [queryParamsHandling]="'preserve'">
                      <img src="assets/images/icons/calendar-icon.png" alt="">
                      Schedule
                    </a>
                  </li>
                </ul>
              </li>
              <!-- NAV 5 END -->
      
              <!-- NAV 6 START -->     
              <!-- <li class="drop-menu-item-set sm-view disabled">
                <button class="nav-a" [matMenuTriggerFor]="navmenu6" >
                  <img src="assets/images/svg/doer-drive-icon.svg" alt=""> 
                  Doer Drive
                  <span class="drop-arrow"></span>
                </button>
                <mat-menu #navmenu6="matMenu" class="nav-mat-menu">
                  <ul class="app-sub-nav">
                    <li>
                      <a href="javascript:void(0);">Folders</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Notes</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Docs</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">MindMaps</a>
                    </li>
                  </ul>
                </mat-menu>
              </li> -->
              <li class="drop-menu-item-set disabled">
                <a href="javascript:void(0);">
                  <!-- <img src="assets/images/svg/doer-drive-icon.svg" alt="">  -->
                  Doer Drive
                </a>
                <span class="drop-arrow"></span>
                <ul class="app-sub-nav">
                  <li>
                    <a href="javascript:void(0);">Folders</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Notes</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Docs</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">MindMaps</a>
                  </li>
                </ul>
              </li>
              <!-- NAV 6 END -->
      
              <!-- NAV 8 START -->   
              <!-- <li class="drop-menu-item-set sm-view disabled">
                <button class="nav-a" [matMenuTriggerFor]="navmenu8" >
                  <img src="assets/images/svg/book-gray-icon.svg" alt=""> 
                  Library
                  <span class="drop-arrow"></span>
                </button>
                <mat-menu #navmenu8="matMenu" class="nav-mat-menu">
                  <ul class="app-sub-nav">
                    <li>
                      <a href="javascript:void(0);">Folders</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Bookmarks</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Content library</a>
                    </li>
                  </ul>
                </mat-menu>
              </li> -->
              <li class="drop-menu-item-set disabled">
                <a href="javascript:void(0);">
                  <!-- <img src="assets/images/svg/book-gray-icon.svg" alt="">  -->
                  Library
                </a>
                <span class="drop-arrow"></span>
                <ul class="app-sub-nav">
                  <li>
                    <a href="javascript:void(0);">Folders</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Bookmarks</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Content library</a>
                  </li>
                </ul>
              </li>
              <!-- NAV 8 END -->
      
              <!-- NAV 9 START --> 
              <!-- <li class="drop-menu-item-set sm-view disabled">
                <button class="nav-a" [matMenuTriggerFor]="navmenu9" >
                  <img src="assets/images/svg/finance-icon.svg" alt="">
                  Finance
                  <span class="drop-arrow"></span>
                </button>
                <mat-menu #navmenu9="matMenu" class="nav-mat-menu">
                  <ul class="app-sub-nav">
                    <li>
                      <a href="javascript:void(0);">Insights</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Teachers Pay</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Student Fees</a>
                    </li>
                  </ul>
                </mat-menu>
              </li> -->
              <li class="drop-menu-item-set disabled">
                <a href="javascript:void(0);">
                  <!-- <img src="assets/images/svg/finance-icon.svg" alt=""> -->
                  Finance
                </a>
                <span class="drop-arrow"></span>
                <ul class="app-sub-nav">
                  <li>
                    <a href="javascript:void(0);">Insights</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Teachers Pay</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Student Fees</a>
                  </li>
                </ul>
              </li>
            </ul>
            <!-- DOERDO PRODUCT MENU END -->
            <!-- DOERCODE PRODUCT MENU START -->
            <ul class="app-main-nav" [hidden]="productInfo !== 'doerCode'">
              <!-- NAV 3 START -->
              <li class="drop-menu-item-set" #elem21 [class.active]="(rla211.isActive || rla212.isActive || rla213.isActive)" [class.show-menu]="(checkClassExists(elem21, 'show-menu') || (rla211.isActive || rla212.isActive || rla213.isActive))">
                <a href="javascript:void(0);" [routerLink]="'/doer-code/my-courses'" [queryParamsHandling]="'preserve'" (click)="showDropMenu($event)">
                  <!-- <img src="assets/images/svg/course-focus-icon.svg" alt="">  -->
                  Live Courses
                </a>
                <span class="drop-arrow" (click)="toggleDropMenu($event)"></span>
                <ul class="app-sub-nav">
                  <li [routerLinkActive]="'active'" #rla212="routerLinkActive">
                    <a href="javascript:void(0);" [routerLink]="'/doer-code/my-courses'" [queryParamsHandling]="'preserve'">My Courses</a>
                  </li>
                  <li [routerLinkActive]="'active'" #rla211="routerLinkActive">
                    <a href="javascript:void(0);" [routerLink]="'/doer-code/live-courses'" [queryParamsHandling]="'preserve'">Explore Courses</a>
                  </li>
                </ul>
              </li>
              <li [routerLinkActive]="'active'" #rla213="routerLinkActive" style="display: none">
                <a href="javascript:void(0);" [routerLink]="'/doer-code/live-courses'" [queryParamsHandling]="'preserve'">Default</a>
              </li>
              <!-- NAV 3 END -->

              <!-- NAV 7 START -->
              <li class="drop-menu-item-set" #elem22 [class.active]="(rla221.isActive || rla222.isActive)" [class.show-menu]="(checkClassExists(elem22, 'show-menu') || (rla221.isActive || rla222.isActive))">
                <a href="javascript:void(0);" [routerLink]="'/doer-code/announcements'" [queryParamsHandling]="'preserve'" (click)="showDropMenu($event)">
                  Messages
                </a>
                <span class="drop-arrow" (click)="toggleDropMenu($event)"></span>
                <ul class="app-sub-nav">
                  <li class="disabled">
                    <a href="javascript:void(0);">Doubts</a>
                  </li>
                  <li class="disabled">
                    <a href="javascript:void(0);">OneBox</a>
                  </li>
                  <li class="disabled">
                    <a href="javascript:void(0);">Inbox</a>
                  </li>
                  <li class="" [routerLinkActive]="'active'" #rla221="routerLinkActive">
                    <a href="javascript:void(0);" [routerLink]="'/doer-code/announcements'" [queryParamsHandling]="'preserve'">Announcements</a>
                  </li>
                  <li [routerLinkActive]="'active'" #rla222="routerLinkActive">
                    <a href="javascript:void(0);" [routerLink]="'/doer-code/channel-communications'" [queryParamsHandling]="'preserve'">Chats</a>
                  </li>
                  <li class="disabled">
                    <a href="javascript:void(0);">Threads</a>
                  </li>
                  <li class="disabled">
                    <a href="javascript:void(0);">Issue Reporting</a>
                  </li>
                  <li class="disabled">
                    <a href="javascript:void(0);">Feedback</a>
                  </li>
                </ul>
              </li>
              <!-- NAV 7 END -->
              
              <!-- NAV 4 START -->
              <li class="drop-menu-item-set sm-view disabled">
                <button class="nav-a" [matMenuTriggerFor]="navmenu4" >
                  <!-- <img src="assets/images/svg/tasks-icon.svg" alt=""> -->
                  Tasks
                  <span class="drop-arrow"></span>
                </button>
                <mat-menu #navmenu4="matMenu" class="nav-mat-menu">
                  <ul class="app-sub-nav">
                    <li class="disabled">
                      <a href="javascript:void(0);">To Do's</a>
                    </li>
                    <li class="disabled">
                      <a href="javascript:void(0);">Class Tests</a>
                    </li>
                    <li class="disabled">
                      <a href="javascript:void(0);">Assignments</a>
                    </li>
                  </ul>
                </mat-menu>
              </li>
              <li class="drop-menu-item-set disabled">
                <a href="javascript:void(0);" (click)="toggleDropMenu($event)">
                  <!-- <img src="assets/images/svg/tasks-icon.svg" alt=""> -->
                  Tasks
                  <span class="drop-arrow"></span>
                </a>
                <ul class="app-sub-nav">
                  <li class="disabled">
                    <a href="javascript:void(0);">To Do's</a>
                  </li>
                  <li class="disabled">
                    <a href="javascript:void(0);">Class Tests</a>
                  </li>
                  <li class="disabled">
                    <a href="javascript:void(0);">Assignments</a>
                  </li>
                </ul>
              </li>
              <!-- NAV 4 END -->
      
              <!-- NAV 5 START -->        
              <!-- <li class="drop-menu-item-set sm-view disabled">
                <button class="nav-a" [matMenuTriggerFor]="navmenu5" >
                  Calendar
                  <span class="drop-arrow"></span>
                </button>
                <mat-menu #navmenu5="matMenu" class="nav-mat-menu">
                  <ul class="app-sub-nav">
                    <li>
                      <a href="javascript:void(0);">Folders</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Notes</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Docs</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">MindMaps</a>
                    </li>
                  </ul>
                </mat-menu>
              </li> -->
              <li class="drop-menu-item-set" #elem24 [class.active]="(rla241.isActive)" [class.show-menu]="(checkClassExists(elem24, 'show-menu') || (rla241.isActive))">
                <a href="javascript:void(0);" [routerLink]="'/doer-code/my-calendar'" [queryParamsHandling]="'preserve'" (click)="showDropMenu($event)">
                  <!-- <img src="assets/images/svg/calendar-icon.svg" alt="">  -->
                  Calendar
                </a>
                <span class="drop-arrow" (click)="toggleDropMenu($event)"></span>
                <ul class="app-sub-nav" (click)="clearLastVisitedMenuSettings()">
                  <li [routerLinkActive]="'active'" #rla241="routerLinkActive">
                    <a href="javascript:void(0);" [routerLink]="'/doer-code/my-calendar/home'" [queryParamsHandling]="'preserve'">
                      <img src="assets/images/icons/calendar-icon.png" alt="">
                      Schedule
                    </a>
                  </li>
                </ul>
              </li>
              <!-- NAV 5 END -->
      
              <!-- NAV 6 START -->     
              <li class="drop-menu-item-set sm-view disabled">
                <button class="nav-a" [matMenuTriggerFor]="navmenu6" >
                  <!-- <img src="assets/images/svg/doer-drive-icon.svg" alt="">  -->
                  Doer Drive
                  <span class="drop-arrow"></span>
                </button>
                <mat-menu #navmenu6="matMenu" class="nav-mat-menu">
                  <ul class="app-sub-nav">
                    <li>
                      <a href="javascript:void(0);">Folders</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Notes</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Docs</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">MindMaps</a>
                    </li>
                  </ul>
                </mat-menu>
              </li>
              <li class="drop-menu-item-set disabled">
                <a href="javascript:void(0);">
                  <!-- <img src="assets/images/svg/doer-drive-icon.svg" alt="">  -->
                  Doer Drive
                  <span class="drop-arrow"></span>
                </a>
                <ul class="app-sub-nav">
                  <li>
                    <a href="javascript:void(0);">Folders</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Notes</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Docs</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">MindMaps</a>
                  </li>
                </ul>
              </li>
              <!-- NAV 6 END -->
      
              <!-- NAV 8 START -->   
              <li class="drop-menu-item-set sm-view disabled">
                <button class="nav-a" [matMenuTriggerFor]="navmenu8" >
                  <!-- <img src="assets/images/svg/book-gray-icon.svg" alt="">  -->
                  Library
                  <span class="drop-arrow"></span>
                </button>
                <mat-menu #navmenu8="matMenu" class="nav-mat-menu">
                  <ul class="app-sub-nav">
                    <li>
                      <a href="javascript:void(0);">Folders</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Bookmarks</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Content library</a>
                    </li>
                  </ul>
                </mat-menu>
              </li>
              <li class="drop-menu-item-set disabled">
                <a href="javascript:void(0);">
                  <!-- <img src="assets/images/svg/book-gray-icon.svg" alt="">  -->
                  Library
                  <span class="drop-arrow"></span>
                </a>
                <ul class="app-sub-nav">
                  <li>
                    <a href="javascript:void(0);">Folders</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Bookmarks</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Content library</a>
                  </li>
                </ul>
              </li>
              <!-- NAV 8 END -->
      
              <!-- NAV 9 START --> 
              <li class="drop-menu-item-set sm-view disabled">
                <button class="nav-a" [matMenuTriggerFor]="navmenu9" >
                  <!-- <img src="assets/images/svg/finance-icon.svg" alt=""> -->
                  Finance
                  <span class="drop-arrow"></span>
                </button>
                <mat-menu #navmenu9="matMenu" class="nav-mat-menu">
                  <ul class="app-sub-nav">
                    <li>
                      <a href="javascript:void(0);">Insights</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Teachers Pay</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Student Fees</a>
                    </li>
                  </ul>
                </mat-menu>
              </li>
              <li class="drop-menu-item-set disabled">
                <a href="javascript:void(0);">
                  <!-- <img src="assets/images/svg/finance-icon.svg" alt=""> -->
                  Finance
                  <span class="drop-arrow"></span>
                </a>
                <ul class="app-sub-nav">
                  <li>
                    <a href="javascript:void(0);">Insights</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Teachers Pay</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Student Fees</a>
                  </li>
                </ul>
              </li>
            </ul>
            <!-- DOERCODE PRODUCT MENU END -->
            <!-- DOERTALK PRODUCT MENU START -->
            <ul class="app-main-nav" [hidden]="productInfo !== 'doerTalk'">
              <!-- NAV 3 START -->
              <li class="drop-menu-item-set" #elem31 [class.active]="(rla311.isActive || rla312.isActive || rla313.isActive)" [class.show-menu]="(checkClassExists(elem31, 'show-menu') || (rla311.isActive || rla312.isActive || rla313.isActive))">
                <a href="javascript:void(0);" [routerLink]="'/doer-talk/my-courses'" [queryParamsHandling]="'preserve'" (click)="showDropMenu($event)">
                  <!-- <img src="assets/images/svg/course-focus-icon.svg" alt="">  -->
                  Live Courses
                </a>
                <span class="drop-arrow" (click)="toggleDropMenu($event)"></span>
                <ul class="app-sub-nav">
                  <li [routerLinkActive]="'active'" #rla312="routerLinkActive">
                    <a href="javascript:void(0);" [routerLink]="'/doer-talk/my-courses'" [queryParamsHandling]="'preserve'">My Courses</a>
                  </li>
                  <li [routerLinkActive]="'active'" #rla311="routerLinkActive">
                    <a href="javascript:void(0);" [routerLink]="'/doer-talk/live-courses'" [queryParamsHandling]="'preserve'">Explore Courses</a>
                  </li>
                </ul>
              </li>
              <li [routerLinkActive]="'active'" #rla313="routerLinkActive" style="display: none">
                <a href="javascript:void(0);" [routerLink]="'/doer-talk/live-courses'" [queryParamsHandling]="'preserve'">Default</a>
              </li>
              <!-- NAV 3 END -->

              <!-- NAV 7 START -->
              <li class="drop-menu-item-set" #elem32 [class.active]="(rla321.isActive || rla322.isActive)" [class.show-menu]="(checkClassExists(elem32, 'show-menu') || (rla321.isActive || rla322.isActive))">
                <a href="javascript:void(0);" [routerLink]="'/doer-talk/announcements'" [queryParamsHandling]="'preserve'" (click)="showDropMenu($event)">
                  <!-- <img src="assets/images/svg/message-icon.svg" alt="">  -->
                  Messages
                </a>
                <span class="drop-arrow" (click)="toggleDropMenu($event)"></span>
                <ul class="app-sub-nav">
                  <li class="disabled">
                    <a href="javascript:void(0);">Doubts</a>
                  </li>
                  <li class="disabled">
                    <a href="javascript:void(0);">OneBox</a>
                  </li>
                  <li class="disabled">
                    <a href="javascript:void(0);">Inbox</a>
                  </li>
                  <li class="" [routerLinkActive]="'active'" #rla321="routerLinkActive">
                    <a href="javascript:void(0);" [routerLink]="'/doer-talk/announcements'" [queryParamsHandling]="'preserve'">Announcements</a>
                  </li>
                  <li [routerLinkActive]="'active'" #rla322="routerLinkActive">
                    <a href="javascript:void(0);" [routerLink]="'/doer-talk/channel-communications'" [queryParamsHandling]="'preserve'">Chats</a>
                  </li>
                  <li class="disabled">
                    <a href="javascript:void(0);">Threads</a>
                  </li>
                  <li class="disabled">
                    <a href="javascript:void(0);">Issue Reporting</a>
                  </li>
                  <li class="disabled">
                    <a href="javascript:void(0);">Feedback</a>
                  </li>
                </ul>
              </li>
              <!-- NAV 7 END -->
              
              <!-- NAV 4 START -->
              <li class="drop-menu-item-set sm-view disabled">
                <button class="nav-a" [matMenuTriggerFor]="navmenu4" >
                  <!-- <img src="assets/images/svg/tasks-icon.svg" alt=""> -->
                  Tasks
                  <span class="drop-arrow"></span>
                </button>
                <mat-menu #navmenu4="matMenu" class="nav-mat-menu">
                  <ul class="app-sub-nav">
                    <li class="disabled">
                      <a href="javascript:void(0);">To Do's</a>
                    </li>
                    <li class="disabled">
                      <a href="javascript:void(0);">Class Tests</a>
                    </li>
                    <li class="disabled">
                      <a href="javascript:void(0);">Assignments</a>
                    </li>
                  </ul>
                </mat-menu>
              </li>
              <li class="drop-menu-item-set disabled">
                <a href="javascript:void(0);" (click)="toggleDropMenu($event)">
                  <!-- <img src="assets/images/svg/tasks-icon.svg" alt=""> -->
                  Tasks
                  <span class="drop-arrow"></span>
                </a>
                <ul class="app-sub-nav">
                  <li class="disabled">
                    <a href="javascript:void(0);">To Do's</a>
                  </li>
                  <li class="disabled">
                    <a href="javascript:void(0);">Class Tests</a>
                  </li>
                  <li class="disabled">
                    <a href="javascript:void(0);">Assignments</a>
                  </li>
                </ul>
              </li>
              <!-- NAV 4 END -->
      
              <!-- NAV 5 START -->        
              <!-- <li class="drop-menu-item-set sm-view disabled">
                <button class="nav-a" [matMenuTriggerFor]="navmenu5" >
                  Calendar
                  <span class="drop-arrow"></span>
                </button>
                <mat-menu #navmenu5="matMenu" class="nav-mat-menu">
                  <ul class="app-sub-nav">
                    <li>
                      <a href="javascript:void(0);">Folders</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Notes</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Docs</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">MindMaps</a>
                    </li>
                  </ul>
                </mat-menu>
              </li> -->
              <li class="drop-menu-item-set" #elem34 [class.active]="(rla341.isActive)" [class.show-menu]="(checkClassExists(elem34, 'show-menu') || (rla341.isActive))">
                <a href="javascript:void(0);" [routerLink]="'/doer-talk/my-calendar'" [queryParamsHandling]="'preserve'" (click)="showDropMenu($event)">
                  Calendar
                </a>
                <span class="drop-arrow" (click)="toggleDropMenu($event)"></span>
                <ul class="app-sub-nav" (click)="clearLastVisitedMenuSettings()">
                  <li [routerLinkActive]="'active'" #rla341="routerLinkActive">
                    <a href="javascript:void(0);" [routerLink]="'/doer-talk/my-calendar/home'" [queryParamsHandling]="'preserve'">
                      <img src="assets/images/icons/calendar-icon.png" alt="">
                      Schedule
                    </a>
                  </li>
                </ul>
              </li>
              <!-- NAV 5 END -->
      
              <!-- NAV 6 START -->     
              <li class="drop-menu-item-set sm-view disabled">
                <button class="nav-a" [matMenuTriggerFor]="navmenu6" >
                  <!-- <img src="assets/images/svg/doer-drive-icon.svg" alt="">  -->
                  Doer Drive
                  <span class="drop-arrow"></span>
                </button>
                <mat-menu #navmenu6="matMenu" class="nav-mat-menu">
                  <ul class="app-sub-nav">
                    <li>
                      <a href="javascript:void(0);">Folders</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Notes</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Docs</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">MindMaps</a>
                    </li>
                  </ul>
                </mat-menu>
              </li>
              <li class="drop-menu-item-set disabled">
                <a href="javascript:void(0);">
                  <!-- <img src="assets/images/svg/doer-drive-icon.svg" alt="">  -->
                  Doer Drive
                  <span class="drop-arrow"></span>
                </a>
                <ul class="app-sub-nav">
                  <li>
                    <a href="javascript:void(0);">Folders</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Notes</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Docs</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">MindMaps</a>
                  </li>
                </ul>
              </li>
              <!-- NAV 6 END -->
      
              <!-- NAV 8 START -->   
              <li class="drop-menu-item-set sm-view disabled">
                <button class="nav-a" [matMenuTriggerFor]="navmenu8" >
                  <!-- <img src="assets/images/svg/book-gray-icon.svg" alt="">  -->
                  Library
                  <span class="drop-arrow"></span>
                </button>
                <mat-menu #navmenu8="matMenu" class="nav-mat-menu">
                  <ul class="app-sub-nav">
                    <li>
                      <a href="javascript:void(0);">Folders</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Bookmarks</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Content library</a>
                    </li>
                  </ul>
                </mat-menu>
              </li>
              <li class="drop-menu-item-set disabled">
                <a href="javascript:void(0);">
                  <!-- <img src="assets/images/svg/book-gray-icon.svg" alt="">  -->
                  Library
                  <span class="drop-arrow"></span>
                </a>
                <ul class="app-sub-nav">
                  <li>
                    <a href="javascript:void(0);">Folders</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Bookmarks</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Content library</a>
                  </li>
                </ul>
              </li>
              <!-- NAV 8 END -->
      
              <!-- NAV 9 START --> 
              <li class="drop-menu-item-set sm-view disabled">
                <button class="nav-a" [matMenuTriggerFor]="navmenu9" >
                  <!-- <img src="assets/images/svg/finance-icon.svg" alt=""> -->
                  Finance
                  <span class="drop-arrow"></span>
                </button>
                <mat-menu #navmenu9="matMenu" class="nav-mat-menu">
                  <ul class="app-sub-nav">
                    <li>
                      <a href="javascript:void(0);">Insights</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Teachers Pay</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Student Fees</a>
                    </li>
                  </ul>
                </mat-menu>
              </li>
              <li class="drop-menu-item-set disabled">
                <a href="javascript:void(0);">
                  <!-- <img src="assets/images/svg/finance-icon.svg" alt=""> -->
                  Finance
                  <span class="drop-arrow"></span>
                </a>
                <ul class="app-sub-nav">
                  <li>
                    <a href="javascript:void(0);">Insights</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Teachers Pay</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Student Fees</a>
                  </li>
                </ul>
              </li>
            </ul>
            <!-- DOERTALK PRODUCT MENU END -->
            <!-- DOERSPARK PRODUCT MENU START -->
            <ul class="app-main-nav" [hidden]="productInfo !== 'doerSpark'">
              <!-- NAV 3 START -->
              <li class="drop-menu-item-set" #elem41 [class.active]="(rla411.isActive || rla412.isActive || rla413.isActive)" [class.show-menu]="(checkClassExists(elem41, 'show-menu') || (rla411.isActive || rla412.isActive || rla413.isActive))">
                <a href="javascript:void(0);" [routerLink]="'/doer-spark/my-courses'" [queryParamsHandling]="'preserve'" (click)="showDropMenu($event)">
                  <!-- <img src="assets/images/svg/course-focus-icon.svg" alt="">  -->
                  Live Courses
                </a>
                <span class="drop-arrow" (click)="toggleDropMenu($event)"></span>
                <ul class="app-sub-nav">
                  <li [routerLinkActive]="'active'" #rla412="routerLinkActive">
                    <a href="javascript:void(0);" [routerLink]="'/doer-spark/my-courses'" [queryParamsHandling]="'preserve'">My Courses</a>
                  </li>
                  <li [routerLinkActive]="'active'" #rla411="routerLinkActive">
                    <a href="javascript:void(0);" [routerLink]="'/doer-spark/live-courses'" [queryParamsHandling]="'preserve'">Explore Courses</a>
                  </li>
                </ul>
              </li>
              <li [routerLinkActive]="'active'" #rla413="routerLinkActive" style="display: none">
                <a href="javascript:void(0);" [routerLink]="'/doer-spark/live-courses'" [queryParamsHandling]="'preserve'">Default</a>
              </li>
              <!-- NAV 3 END -->

              <!-- NAV 7 START -->
              <li class="drop-menu-item-set" #elem42 [class.active]="(rla421.isActive || rla422.isActive)" [class.show-menu]="(checkClassExists(elem32, 'show-menu') || (rla421.isActive || rla422.isActive))">
                <a href="javascript:void(0);" [routerLink]="'/doer-spark/announcements'" [queryParamsHandling]="'preserve'" (click)="showDropMenu($event)">
                  <!-- <img src="assets/images/svg/message-icon.svg" alt="">  -->
                  Messages
                </a>
                <span class="drop-arrow" (click)="toggleDropMenu($event)"></span>
                <ul class="app-sub-nav">
                  <li class="disabled">
                    <a href="javascript:void(0);">Doubts</a>
                  </li>
                  <li class="disabled">
                    <a href="javascript:void(0);">OneBox</a>
                  </li>
                  <li class="disabled">
                    <a href="javascript:void(0);">Inbox</a>
                  </li>
                  <li class="" [routerLinkActive]="'active'" #rla421="routerLinkActive">
                    <a href="javascript:void(0);" [routerLink]="'/doer-spark/announcements'" [queryParamsHandling]="'preserve'">Announcements</a>
                  </li>
                  <li [routerLinkActive]="'active'" #rla422="routerLinkActive">
                    <a href="javascript:void(0);" [routerLink]="'/doer-spark/channel-communications'" [queryParamsHandling]="'preserve'">Chats</a>
                  </li>
                  <li class="disabled">
                    <a href="javascript:void(0);">Threads</a>
                  </li>
                  <li class="disabled">
                    <a href="javascript:void(0);">Issue Reporting</a>
                  </li>
                  <li class="disabled">
                    <a href="javascript:void(0);">Feedback</a>
                  </li>
                </ul>
              </li>
              <!-- NAV 7 END -->
              
              <!-- NAV 4 START -->
              <li class="drop-menu-item-set sm-view disabled">
                <button class="nav-a" [matMenuTriggerFor]="navmenu4" >
                  <!-- <img src="assets/images/svg/tasks-icon.svg" alt=""> -->
                  Tasks
                  <span class="drop-arrow"></span>
                </button>
                <mat-menu #navmenu4="matMenu" class="nav-mat-menu">
                  <ul class="app-sub-nav">
                    <li class="disabled">
                      <a href="javascript:void(0);">To Do's</a>
                    </li>
                    <li class="disabled">
                      <a href="javascript:void(0);">Class Tests</a>
                    </li>
                    <li class="disabled">
                      <a href="javascript:void(0);">Assignments</a>
                    </li>
                  </ul>
                </mat-menu>
              </li>
              <li class="drop-menu-item-set disabled">
                <a href="javascript:void(0);" (click)="toggleDropMenu($event)">
                  <!-- <img src="assets/images/svg/tasks-icon.svg" alt=""> -->
                  Tasks
                  <span class="drop-arrow"></span>
                </a>
                <ul class="app-sub-nav">
                  <li class="disabled">
                    <a href="javascript:void(0);">To Do's</a>
                  </li>
                  <li class="disabled">
                    <a href="javascript:void(0);">Class Tests</a>
                  </li>
                  <li class="disabled">
                    <a href="javascript:void(0);">Assignments</a>
                  </li>
                </ul>
              </li>
              <!-- NAV 4 END -->
      
              <!-- NAV 5 START -->        
              <!-- <li class="drop-menu-item-set sm-view disabled">
                <button class="nav-a" [matMenuTriggerFor]="navmenu5" >
                  Calendar
                  <span class="drop-arrow"></span>
                </button>
                <mat-menu #navmenu5="matMenu" class="nav-mat-menu">
                  <ul class="app-sub-nav">
                    <li>
                      <a href="javascript:void(0);">Folders</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Notes</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Docs</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">MindMaps</a>
                    </li>
                  </ul>
                </mat-menu>
              </li> -->
              <li class="drop-menu-item-set" #elem44 [class.active]="(rla441.isActive)" [class.show-menu]="(checkClassExists(elem44, 'show-menu') || (rla441.isActive))">
                <a href="javascript:void(0);" [routerLink]="'/doer-spark/my-calendar'" [queryParamsHandling]="'preserve'" (click)="showDropMenu($event)">
                  Calendar
                </a>
                <span class="drop-arrow" (click)="toggleDropMenu($event)"></span>
                <ul class="app-sub-nav" (click)="clearLastVisitedMenuSettings()">
                  <li [routerLinkActive]="'active'" #rla441="routerLinkActive">
                    <a href="javascript:void(0);" [routerLink]="'/doer-spark/my-calendar/home'" [queryParamsHandling]="'preserve'">
                      <img src="assets/images/icons/calendar-icon.png" alt="">
                      Schedule
                    </a>
                  </li>
                </ul>
              </li>
              <!-- NAV 5 END -->
      
              <!-- NAV 6 START -->     
              <li class="drop-menu-item-set sm-view disabled">
                <button class="nav-a" [matMenuTriggerFor]="navmenu6" >
                  <!-- <img src="assets/images/svg/doer-drive-icon.svg" alt="">  -->
                  Doer Drive
                  <span class="drop-arrow"></span>
                </button>
                <mat-menu #navmenu6="matMenu" class="nav-mat-menu">
                  <ul class="app-sub-nav">
                    <li>
                      <a href="javascript:void(0);">Folders</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Notes</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Docs</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">MindMaps</a>
                    </li>
                  </ul>
                </mat-menu>
              </li>
              <li class="drop-menu-item-set disabled">
                <a href="javascript:void(0);">
                  <!-- <img src="assets/images/svg/doer-drive-icon.svg" alt="">  -->
                  Doer Drive
                  <span class="drop-arrow"></span>
                </a>
                <ul class="app-sub-nav">
                  <li>
                    <a href="javascript:void(0);">Folders</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Notes</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Docs</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">MindMaps</a>
                  </li>
                </ul>
              </li>
              <!-- NAV 6 END -->
      
              <!-- NAV 8 START -->   
              <li class="drop-menu-item-set sm-view disabled">
                <button class="nav-a" [matMenuTriggerFor]="navmenu8" >
                  <!-- <img src="assets/images/svg/book-gray-icon.svg" alt="">  -->
                  Library
                  <span class="drop-arrow"></span>
                </button>
                <mat-menu #navmenu8="matMenu" class="nav-mat-menu">
                  <ul class="app-sub-nav">
                    <li>
                      <a href="javascript:void(0);">Folders</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Bookmarks</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Content library</a>
                    </li>
                  </ul>
                </mat-menu>
              </li>
              <li class="drop-menu-item-set disabled">
                <a href="javascript:void(0);">
                  <!-- <img src="assets/images/svg/book-gray-icon.svg" alt="">  -->
                  Library
                  <span class="drop-arrow"></span>
                </a>
                <ul class="app-sub-nav">
                  <li>
                    <a href="javascript:void(0);">Folders</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Bookmarks</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Content library</a>
                  </li>
                </ul>
              </li>
              <!-- NAV 8 END -->
      
              <!-- NAV 9 START --> 
              <li class="drop-menu-item-set sm-view disabled">
                <button class="nav-a" [matMenuTriggerFor]="navmenu9" >
                  <!-- <img src="assets/images/svg/finance-icon.svg" alt=""> -->
                  Finance
                  <span class="drop-arrow"></span>
                </button>
                <mat-menu #navmenu9="matMenu" class="nav-mat-menu">
                  <ul class="app-sub-nav">
                    <li>
                      <a href="javascript:void(0);">Insights</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Teachers Pay</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Student Fees</a>
                    </li>
                  </ul>
                </mat-menu>
              </li>
              <li class="drop-menu-item-set disabled">
                <a href="javascript:void(0);">
                  <!-- <img src="assets/images/svg/finance-icon.svg" alt=""> -->
                  Finance
                  <span class="drop-arrow"></span>
                </a>
                <ul class="app-sub-nav">
                  <li>
                    <a href="javascript:void(0);">Insights</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Teachers Pay</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Student Fees</a>
                  </li>
                </ul>
              </li>
            </ul>
            <!-- DOERTALK PRODUCT MENU END -->
            <!-- DOERMATH PRODUCT MENU START -->
            <ul class="app-main-nav" [hidden]="productInfo !== 'doerMath'">
              <!-- NAV 3 START -->
              <li class="drop-menu-item-set" #elem51 [class.active]="(rla511.isActive || rla512.isActive || rla513.isActive)" [class.show-menu]="(checkClassExists(elem51, 'show-menu') || (rla511.isActive || rla512.isActive || rla513.isActive))">
                <a href="javascript:void(0);" [routerLink]="'/doer-math/my-courses'" [queryParamsHandling]="'preserve'" (click)="showDropMenu($event)">
                  <!-- <img src="assets/images/svg/course-focus-icon.svg" alt="">  -->
                  Live Courses
                </a>
                <span class="drop-arrow" (click)="toggleDropMenu($event)"></span>
                <ul class="app-sub-nav">
                  <li [routerLinkActive]="'active'" #rla512="routerLinkActive">
                    <a href="javascript:void(0);" [routerLink]="'/doer-math/my-courses'" [queryParamsHandling]="'preserve'">My Courses</a>
                  </li>
                  <li [routerLinkActive]="'active'" #rla511="routerLinkActive">
                    <a href="javascript:void(0);" [routerLink]="'/doer-math/live-courses'" [queryParamsHandling]="'preserve'">Explore Courses</a>
                  </li>
                </ul>
              </li>
              <li [routerLinkActive]="'active'" #rla513="routerLinkActive" style="display: none">
                <a href="javascript:void(0);" [routerLink]="'/doer-math/live-courses'" [queryParamsHandling]="'preserve'">Default</a>
              </li>
              <!-- NAV 3 END -->

              <!-- NAV 7 START -->
              <li class="drop-menu-item-set" #elem52 [class.active]="(rla521.isActive || rla522.isActive)" [class.show-menu]="(checkClassExists(elem52, 'show-menu') || (rla521.isActive || rla522.isActive))">
                <a href="javascript:void(0);" [routerLink]="'/doer-math/announcements'" [queryParamsHandling]="'preserve'" (click)="showDropMenu($event)">
                  <!-- <img src="assets/images/svg/message-icon.svg" alt="">  -->
                  Messages
                </a>
                <span class="drop-arrow" (click)="toggleDropMenu($event)"></span>
                <ul class="app-sub-nav">
                  <li class="disabled">
                    <a href="javascript:void(0);">Doubts</a>
                  </li>
                  <li class="disabled">
                    <a href="javascript:void(0);">OneBox</a>
                  </li>
                  <li class="disabled">
                    <a href="javascript:void(0);">Inbox</a>
                  </li>
                  <li class="" [routerLinkActive]="'active'" #rla521="routerLinkActive">
                    <a href="javascript:void(0);" [routerLink]="'/doer-math/announcements'" [queryParamsHandling]="'preserve'">Announcements</a>
                  </li>
                  <li [routerLinkActive]="'active'" #rla522="routerLinkActive">
                    <a href="javascript:void(0);" [routerLink]="'/doer-math/channel-communications'" [queryParamsHandling]="'preserve'">Chats</a>
                  </li>
                  <li class="disabled">
                    <a href="javascript:void(0);">Threads</a>
                  </li>
                  <li class="disabled">
                    <a href="javascript:void(0);">Issue Reporting</a>
                  </li>
                  <li class="disabled">
                    <a href="javascript:void(0);">Feedback</a>
                  </li>
                </ul>
              </li>
              <!-- NAV 7 END -->
              
              <!-- NAV 4 START -->
              <li class="drop-menu-item-set sm-view disabled">
                <button class="nav-a" [matMenuTriggerFor]="navmenu4" >
                  <!-- <img src="assets/images/svg/tasks-icon.svg" alt=""> -->
                  Tasks
                  <span class="drop-arrow"></span>
                </button>
                <mat-menu #navmenu4="matMenu" class="nav-mat-menu">
                  <ul class="app-sub-nav">
                    <li class="disabled">
                      <a href="javascript:void(0);">To Do's</a>
                    </li>
                    <li class="disabled">
                      <a href="javascript:void(0);">Class Tests</a>
                    </li>
                    <li class="disabled">
                      <a href="javascript:void(0);">Assignments</a>
                    </li>
                  </ul>
                </mat-menu>
              </li>
              <li class="drop-menu-item-set disabled">
                <a href="javascript:void(0);" (click)="toggleDropMenu($event)">
                  <!-- <img src="assets/images/svg/tasks-icon.svg" alt=""> -->
                  Tasks
                  <span class="drop-arrow"></span>
                </a>
                <ul class="app-sub-nav">
                  <li class="disabled">
                    <a href="javascript:void(0);">To Do's</a>
                  </li>
                  <li class="disabled">
                    <a href="javascript:void(0);">Class Tests</a>
                  </li>
                  <li class="disabled">
                    <a href="javascript:void(0);">Assignments</a>
                  </li>
                </ul>
              </li>
              <!-- NAV 4 END -->
      
              <!-- NAV 5 START -->
              <li class="drop-menu-item-set"  #elem54 [class.active]="(rla541.isActive)" [class.show-menu]="(checkClassExists(elem54, 'show-menu') || (rla541.isActive))">
                <a href="javascript:void(0);" [routerLink]="'/doer-math/my-calendar'" [queryParamsHandling]="'preserve'" (click)="showDropMenu($event)">
                  <!-- <img src="assets/images/svg/calendar-icon.svg" alt="">  -->
                  Calendar
                </a>
                <span class="drop-arrow" (click)="toggleDropMenu($event)"></span>
                <ul class="app-sub-nav" (click)="clearLastVisitedMenuSettings()">
                  <li [routerLinkActive]="'active'" #rla541="routerLinkActive">
                    <a href="javascript:void(0);" [routerLink]="'/doer-math/my-calendar/home'" [queryParamsHandling]="'preserve'">
                      <img src="assets/images/icons/calendar-icon.png" alt="">
                      Schedule
                    </a>
                  </li>
                </ul>
                <ul class="app-sub-nav">
                  <li>
                    <a href="javascript:void(0);">Folders</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Notes</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Docs</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">MindMaps</a>
                  </li>
                </ul>
              </li>
              <!-- NAV 5 END -->
      
              <!-- NAV 6 START -->     
              <li class="drop-menu-item-set sm-view disabled">
                <button class="nav-a" [matMenuTriggerFor]="navmenu6" >
                  <!-- <img src="assets/images/svg/doer-drive-icon.svg" alt="">  -->
                  Doer Drive
                  <span class="drop-arrow"></span>
                </button>
                <mat-menu #navmenu6="matMenu" class="nav-mat-menu">
                  <ul class="app-sub-nav">
                    <li>
                      <a href="javascript:void(0);">Folders</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Notes</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Docs</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">MindMaps</a>
                    </li>
                  </ul>
                </mat-menu>
              </li>
              <li class="drop-menu-item-set disabled">
                <a href="javascript:void(0);">
                  <!-- <img src="assets/images/svg/doer-drive-icon.svg" alt="">  -->
                  Doer Drive
                  <span class="drop-arrow"></span>
                </a>
                <ul class="app-sub-nav">
                  <li>
                    <a href="javascript:void(0);">Folders</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Notes</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Docs</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">MindMaps</a>
                  </li>
                </ul>
              </li>
              <!-- NAV 6 END -->
      
              <!-- NAV 8 START -->   
              <li class="drop-menu-item-set sm-view disabled">
                <button class="nav-a" [matMenuTriggerFor]="navmenu8" >
                  <!-- <img src="assets/images/svg/book-gray-icon.svg" alt="">  -->
                  Library
                  <span class="drop-arrow"></span>
                </button>
                <mat-menu #navmenu8="matMenu" class="nav-mat-menu">
                  <ul class="app-sub-nav">
                    <li>
                      <a href="javascript:void(0);">Folders</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Bookmarks</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Content library</a>
                    </li>
                  </ul>
                </mat-menu>
              </li>
              <li class="drop-menu-item-set disabled">
                <a href="javascript:void(0);">
                  <!-- <img src="assets/images/svg/book-gray-icon.svg" alt="">  -->
                  Library
                  <span class="drop-arrow"></span>
                </a>
                <ul class="app-sub-nav">
                  <li>
                    <a href="javascript:void(0);">Folders</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Bookmarks</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Content library</a>
                  </li>
                </ul>
              </li>
              <!-- NAV 8 END -->
      
              <!-- NAV 9 START --> 
              <li class="drop-menu-item-set sm-view disabled">
                <button class="nav-a" [matMenuTriggerFor]="navmenu9" >
                  <!-- <img src="assets/images/svg/finance-icon.svg" alt=""> -->
                  Finance
                  <span class="drop-arrow"></span>
                </button>
                <mat-menu #navmenu9="matMenu" class="nav-mat-menu">
                  <ul class="app-sub-nav">
                    <li>
                      <a href="javascript:void(0);">Insights</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Teachers Pay</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Student Fees</a>
                    </li>
                  </ul>
                </mat-menu>
              </li>
              <li class="drop-menu-item-set disabled">
                <a href="javascript:void(0);">
                  <!-- <img src="assets/images/svg/finance-icon.svg" alt=""> -->
                  Finance
                  <span class="drop-arrow"></span>
                </a>
                <ul class="app-sub-nav">
                  <li>
                    <a href="javascript:void(0);">Insights</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Teachers Pay</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Student Fees</a>
                  </li>
                </ul>
              </li>
            </ul>
            <!-- DOERMATH PRODUCT MENU END -->
          </ng-scrollbar>
        </div>
      </div>
    
      <div class="app-nav-second-container">
        <div class="app-nav-breadcrumb-container" id="left-main-secondmenu-head-breadcrumb-section"></div>
        <div class="app-nav-second-menu" id="left-main-second-menu-bar"></div>
      </div>
      </div>
    <!-- </div> -->

<!-- <div class="count">
  <span class="list">count: <strong>80</strong></span>
  <span class="list">number: <strong>60</strong></span>
</div> -->
<app-portal [portalId]="'current-user-badge'" *ngIf="!userLoginHidden">
  <div class="c-header c-header-new" *ngIf="currentStudentData">
    <div class="n-user">
      <div class="details">
          <span class="nm">
            {{(currentStudentData.nick_name ? currentStudentData.nick_name : currentStudentData.name ) | titlecase}}
            <span class="s-id">ID: #{{ currentStudentData.id }}</span>
          </span>
          <span class="badge badge-danger" (click)="openStudentSelectionDialog()">Switch</span>
      </div>
      <div class="photo">
          <img src="../../../../assets/images/icons/user-icon.png" *ngIf="!currentStudentData.avatar_link">
          <img [src]="imagePath + currentStudentData.avatar_link" *ngIf="currentStudentData.avatar_link">
      </div>
    </div>
  </div>
</app-portal>