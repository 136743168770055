import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { PortalComponent } from '../content/subject/display-content/right-section/right-section-footer/portal/portal.component';
import { AnsweredQfDirective } from './directive/answered-qf.directive';
import { AutofocusDirective } from './directive/autofocus.directive';
import { ChangeHttpsToHttpDirective } from './directive/change-https-to-http.directive';
import { keyTrackDirective } from './directive/keypress-track.directive';
import { EnterToSubmitDirective } from './directive/press-enter-submit.ditective';
import { uniqueValidatorEmailDirective } from './directive/uniqueemail.directive';
import { uniqueValidatorPhoneDirective } from './directive/uniquephone.directive';
import { CheckMainQuestionPipe } from './pipes/check-main-question.pipe';
import { GetLectureId } from './pipes/get-lecture-id.pipe';
import { GetStudentExamDate } from './pipes/get-student-exam-date.pipe';
import { GetStudentGrade } from './pipes/get-student-grade.pipe';
import { PlaceholderPipe } from './pipes/placeholder.pipe';
import { LeftsectionfilterPipe } from './pipes/leftsectionfilter.pipe';
import { MathModule } from './shared/math.module';
import { ToastMessageBarModule } from '@studentapp_shared/utils-library/toast-message-bar/toast-message-bar.module';
import { SafeHtmlModule } from './pipes/safe-html/safe-html.module';
import { MatNativeDateModule } from '@angular/material/core';



@NgModule({
  imports: [
    CommonModule,
    PortalModule,
    NgScrollbarModule,
    MathModule,
    SafeHtmlModule,
    MatNativeDateModule,
    // ToastMessageBarModule
  ],
  declarations: [
    GetLectureId,
    PortalComponent,
    PlaceholderPipe,
    GetStudentGrade,
    GetStudentExamDate,
    CheckMainQuestionPipe,
    uniqueValidatorEmailDirective,
    uniqueValidatorPhoneDirective,
    AnsweredQfDirective,
    ChangeHttpsToHttpDirective,
    keyTrackDirective,
    EnterToSubmitDirective,
    AutofocusDirective,
    LeftsectionfilterPipe
  ],
  exports: [
    /* Pipe */
    GetLectureId,
    CheckMainQuestionPipe,
    GetStudentGrade,
    GetStudentExamDate,
    PlaceholderPipe,
    LeftsectionfilterPipe,
    /* Directive */
    uniqueValidatorEmailDirective,
    uniqueValidatorPhoneDirective,
    AnsweredQfDirective,
    ChangeHttpsToHttpDirective,
    keyTrackDirective,
    EnterToSubmitDirective,
    AutofocusDirective,
    /* Component */
    PortalComponent,
    /* Module */
    NgScrollbarModule,
    MathModule,
    MatNativeDateModule,
  ]
})
export class CoreModule { }
