import { Directive, Renderer2, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appAnsweredQf]'
})
export class AnsweredQfDirective {
  @Input('appAnsweredQf') set section(section) {
    console.log({ section });
    if (!section) return;
    let total_main_questions = section.main_questions.length;
    let answered_main_questions = 0;
    if (total_main_questions > 0) {
      section.main_questions.forEach(main_question => {
        if (main_question.alerts) answered_main_questions++;
      });
      if (answered_main_questions === total_main_questions) {
        this.renderer.addClass(this.hostElement.nativeElement, 'selected');
      } else if (answered_main_questions > 0 && answered_main_questions < total_main_questions) {
        this.renderer.addClass(this.hostElement.nativeElement, 'selected light');
      }
    }
  };
  constructor(private renderer: Renderer2,
    private hostElement: ElementRef) {
      console.log('TEST')
     }

}
