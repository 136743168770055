<div class="product-sw-lists">
    <ul class="list-rw">
        <ng-container *ngFor="let _product_nav_item of productDataListArray">
            <li [class.active]="currentActiveProductType === _product_nav_item.product_type">
                <a [routerLink]="['/' + _product_nav_item.product_route_path]" (click)="openLink($event)">
                    <span class="ico">
                        <img class="menuImg no-inv widthimage22" [src]="_product_nav_item.product_icon_path">
                    </span>
                    <span class="txt">{{ _product_nav_item.product_heading }}</span>
                </a>
            </li>
        </ng-container>
    </ul>
</div>