import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { PRODUCT_SETTING_DATA } from '@studentapp_core/common/constant';
import { DataService } from '@studentapp_core/services/DataService/data.service';
import { BranchProductService } from '@studentapp_core/services/branch-product.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-product-switcher-bottom-sheet',
  templateUrl: './product-switcher-bottom-sheet.component.html',
  styleUrls: ['./product-switcher-bottom-sheet.component.scss']
})
export class ProductSwitcherBottomSheetComponent implements OnInit, OnDestroy {

  predefinedProductLists = PRODUCT_SETTING_DATA;
  productDataListArray = [];
  currentActiveProductType = '';
  subscriptions: Subscription[] = [];

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<ProductSwitcherBottomSheetComponent>,
    private dataService: DataService,
    private branchProductService: BranchProductService,
  ) {
    this.generateProductsListArray();
    this.listenForBranchProductRelatedChanges();
  }

  generateProductsListArray() {
    Object.keys(this.predefinedProductLists).forEach(key => {
      this.productDataListArray.push(this.predefinedProductLists[key]);
    });
  }

  openLink(event: MouseEvent): void {
    this.dataService.updateStudentSelection([]);
    this._bottomSheetRef.dismiss();
    // event.preventDefault();
  }


  listenForBranchProductRelatedChanges() {
    this.subscriptions.push(
      this.branchProductService.currentLiveCourseProduct$.subscribe(
        (response: any) => {
          if (response && response.product_setting) {
            this.currentActiveProductType = response.product_setting.product_type;
          }
        }
      )
    )
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
