import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgScrollbarModule } from "ngx-scrollbar";
import { TimerComponent } from "../../content/timer/timer.component";
import { PortalModule } from '@angular/cdk/portal';

@NgModule({
    imports: [
        CommonModule,
        NgScrollbarModule,
    ],
    declarations: [
        TimerComponent,
    ],
    exports: [
        TimerComponent,
        NgScrollbarModule,
        CommonModule
    ]
})

export class SharedComponentsModule { }