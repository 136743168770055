import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../services/APIService/auth.service';


@Pipe({
    name: 'placeholder'
})

export class PlaceholderPipe implements PipeTransform {

    constructor(
        private authService: AuthService) { }

    transform(value: any): any {

        let currentStudentData = null;

        if (this.authService.getCurrentStudentData())
            currentStudentData = this.authService.getCurrentStudentData();
        else currentStudentData = null;

        if (!value || !currentStudentData) return value;

        const result = value.replace(new RegExp('<span class="placeholder" name="Student Name">{Student Name}</span>', 'g'),
            `<span style="font-weight: 600">${currentStudentData.nick_name ? currentStudentData.nick_name : currentStudentData.name}</span>`)
            .replace(new RegExp('<span class="placeholder" name="Student Name">{Student Name}</span>', 'g'), `<span style="font-weight: 600">${currentStudentData.nick_name ? currentStudentData.nick_name : currentStudentData.name}</span>`);

        return result;

    }
}